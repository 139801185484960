var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"my-2 ml-1"},[_c('b-row',[_c('b-col',{staticClass:"px-0"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"mr-2 flex flexMiddle"},[_c('span',{class:[
                'optionLabel',
                'mr-1',
                `label_1`
              ]},[_vm._v("国内")])]),_c('b-form-input',{class:[
              !_vm.isLog && _vm.optionData(_vm.options[0].id).errMessages.length > 0 ? 'errItem' : '',
              `template_${_vm.options[0].view_template}`
            ],attrs:{"type":_vm.controlInputType(_vm.options[0].rule_id_list),"disabled":!_vm.writable,"value":!_vm.isLog ? _vm.optionData(_vm.options[0].id).field_answer_text
            : _vm.logData[`${_vm.fieldId}_${_vm.options[0].id}_1`].field_answer_text,"formatter":_vm.controlFormatter(_vm.options[0].rule_id_list),"maxLength":_vm.options[0].max,"placeholder":_vm.options[0].placeholder},on:{"input":function($event){return _vm.kickSetText($event, _vm.options[0].id)}}}),_c('b-input-group-append',{staticClass:"ml-2 flex flexMiddle"},[_vm._v(" "+_vm._s(_vm.options[0].suffix)+" ")])],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"px-0"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"mr-2 flex flexMiddle"},[_c('span',{class:[
                'optionLabel',
                'mr-1',
                `label_1`
              ]},[_vm._v("国外")])]),_c('b-form-input',{class:[
            !_vm.isLog && _vm.optionData(_vm.options[1].id).errMessages.length > 0 ? 'errItem' : '',
              `template_${_vm.options[1].view_template}`
            ],attrs:{"type":_vm.controlInputType(_vm.options[1].rule_id_list),"disabled":!_vm.writable,"value":!_vm.isLog ? _vm.optionData(_vm.options[1].id).field_answer_text
            : _vm.logData[`${_vm.fieldId}_${_vm.options[1].id}_1`].field_answer_text,"formatter":_vm.controlFormatter(_vm.options[1].rule_id_list),"maxLength":_vm.options[1].max,"placeholder":_vm.options[1].placeholder},on:{"input":function($event){return _vm.kickSetText($event, _vm.options[1].id)}}}),_c('b-input-group-append',{staticClass:"ml-2 flex flexMiddle"},[_vm._v(" "+_vm._s(_vm.options[1].suffix)+" ")])],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"px-0"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"mr-2 flex flexMiddle"},[_c('span',{class:[
                'optionLabel',
                'mr-1',
                `label_1`
              ]},[_vm._v("合計人数")])]),_c('b-form-input',{class:[
              `template_4`
            ],attrs:{"value":_vm.sumValue,"disabled":true,"maxLength":5,"placeholder":""}}),_c('b-input-group-append',{staticClass:"ml-2 flex flexMiddle"},[_vm._v(" 人 ")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }