var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"my-2 ml-1"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-input-group',[(!_vm.isLog && !_vm.optionData(option.id).uploadedFileName && !_vm.optionData(option.id).dbFileName)?_c('span',{staticClass:"mb-2 fileName"},[_vm._v("添付ファイル名：ファイルが選択されていません")]):(!_vm.isLog && _vm.optionData(option.id).uploadedFileName)?_c('span',{staticClass:"mb-2 fileName"},[_vm._v(" 添付ファイル名："),_c('b-link',{on:{"click":function($event){_vm.downloadApploadedFile(_vm.optionData(option.id).uploadedFileKey, 0, _vm.optionData(option.id).uploadedFileName)}}},[_vm._v(_vm._s(_vm.optionData(option.id).uploadedFileName))]),(!_vm.isLog && _vm.writable)?_c('img',{staticClass:"delete_file_btn",attrs:{"src":require("@/assets/svg/close.svg")},on:{"click":function($event){return _vm.kickDeleteFile(option.id)}}}):_vm._e()],1):(!_vm.isLog && !_vm.optionData(option.id).uploadedFileName ? _vm.optionData(option.id).dbFileName : _vm.logData[`${_vm.fieldId}_${option.id}_1`].file_path)?_c('span',{staticClass:"mb-2 fileName"},[_vm._v(" 添付ファイル名： "),_c('b-link',{staticClass:"mb-1 font-is-bold",on:{"click":function($event){_vm.downloadFormFile(
                _vm.fieldId, option.id,
                !_vm.isLog ? _vm.optionData(option.id).dbFileName : _vm.logData[`${_vm.fieldId}_${option.id}_1`].file_path,
                _vm.isOld,
                _vm.isLog,
                _vm.logData ? _vm.logData.sort : null)}}},[_vm._v(_vm._s(!_vm.isLog ? _vm.optionData(option.id).dbFileName : _vm.logData[`${_vm.fieldId}_${option.id}_1`].file_path))]),(!_vm.isLog && _vm.writable)?_c('b-form-checkbox',{staticClass:"ml-3 inlineBlock",attrs:{"checked":_vm.optionData(option.id).do_delete_file,"switches":""},on:{"change":function($event){_vm.toggleDoDeleteDbFile(_vm.optionData(option.id))}}},[_vm._v("削除")]):_vm._e()],1):_c('span',{staticClass:"mb-2 fileName"},[_vm._v("添付ファイル名：ファイルが選択されていません")])]),(_vm.writable && _vm.inNoneInput === false)?_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"mr-1 flex flexMiddle"},[_vm._v(" "+_vm._s(option.label)+" "+_vm._s(option.prefix)+" ")]),_c('div',{class:[
              _vm.isDragging ? 'is-dragging' : '',
              !_vm.isLog && _vm.optionData(option.id).errMessages.length > 0 ? 'errItem' : '',
              'drop', 'flex', 'flexMiddle', 'flexCenter'
            ],on:{"dragover":function($event){$event.preventDefault();return _vm.toggleDrag($event, true)},"dragleave":function($event){$event.preventDefault();return _vm.toggleDrag($event, false)},"drop":function($event){$event.preventDefault();return _vm.onDrop($event, option.id)}}},[_c('span',[_c('span',{class:[_vm.isDragging ? 'is-dragging' : '',
                  'dragText',
                  'fw-6']},[_vm._v("ここにファイルをドラッグ＆ドロップ または")]),_c('label',{staticClass:"fileText fw-6 selectFile",attrs:{"for":`file_${_vm.fieldId}`}},[_vm._v("［ ファイルを選択 ］")]),_c('p',{class:[_vm.isDragging ? 'is-dragging' : '', 'fileNotation', 'mb-0']},[_vm._v(" （ファイルを再アップロードした場合アップロード済みのファイルは上書きされます） ")]),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":`file_${_vm.fieldId}`},on:{"change":function($event){return _vm.upload($event, option.id)}}})])]),_c('b-input-group-append',{staticClass:"ml-1 flex flexMiddle"},[_vm._v(" "+_vm._s(option.suffix)+" ")])],1):_vm._e()],1)],1),(!_vm.isOld && !_vm.isLog)?_c('b-row',{staticClass:"ml-1"},_vm._l((_vm.optionData(option.id).errMessages),function(msg,index){return _c('p',{key:index,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }