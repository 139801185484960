var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10px special-3-wrap"},[_c('div',{staticClass:"my-2 ml-4"},_vm._l((_vm.maxAnswerNums),function(line){return _c('div',{key:line,staticClass:"groupInputs mt-2 mb-3"},[_vm._m(0,true),_c('div',{staticClass:"deleteBtn"},[(_vm.writable)?_c('img',{staticClass:"tdDeleteBtn",attrs:{"src":require("@/assets/svg/close.svg")},on:{"click":function($event){return _vm.removeTr(line)}}}):_vm._e()]),_c('div',{staticClass:"flex flexMiddle mx-2"},[(_vm.dicision)?_c('div',{staticClass:"mr-2"},[_vm._v(" 決定 "),_c('b-form-checkbox',{staticClass:"gas-form-checkbox-parts checkbox-ccc ml-1 dicisiton-check",attrs:{"disabled":!_vm.writable,"name":`check${line}`,"checked":_vm.checkedState(_vm.options[5].id, line),"value":true,"switches":""},on:{"change":function($event){return _vm.kickSetText($event, _vm.options[5].id, line)}}})],1):_vm._e(),_c('div',{staticClass:"input-area"},[_c('div',{staticClass:"flex"},[_c('div',{class:[
                'mr-20px mt-10px',
                'option-template-' + _vm.options[0].view_template,
              ]},[_c('span',{staticClass:"ml-1 mb-1"},[_vm._v(_vm._s(_vm.options[0].label))]),(_vm.options[0].input_type===_vm.tableInputTypes.TEXT ||
                  _vm.options[0].input_type===_vm.tableInputTypes.NUMBER)?_c('b-form-input',{class:[
                  !_vm.isLog && _vm.optionData(_vm.options[0].id, line).errMessages.length > 0 ? 'errItem' : '',
                  'inputTemplate_' + _vm.options[0].view_template,
                ],attrs:{"disabled":!_vm.writable,"maxLength":_vm.options[0].max,"value":!_vm.isLog ? _vm.optionData(_vm.options[0].id, line).field_answer_text
                : _vm.logData[`${_vm.fieldId}_${_vm.options[0].id}_${line}`].field_answer_text,"placeholder":_vm.options[0].placeholder},on:{"input":function($event){return _vm.kickSetText($event, _vm.options[0].id, line)}}}):_vm._e(),(!_vm.isLog && !_vm.isOld)?_c('div',{staticClass:"ml-1"},_vm._l((_vm.optionData(_vm.options[0].id, line).errMessages),function(msg,index){return _c('p',{key:`special3_err0_${index}`,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg))])}),0):_vm._e()],1),_c('div',{class:[
                'mr-20px mt-10px',
                'option-template-' + _vm.options[1].view_template,
              ]},[_c('span',{staticClass:"ml-1 mb-1"},[_vm._v(_vm._s(_vm.options[1].label))]),(_vm.options[1].input_type===_vm.tableInputTypes.TEXT ||
                  _vm.options[1].input_type===_vm.tableInputTypes.NUMBER)?_c('b-form-input',{class:[
                  !_vm.isLog && _vm.optionData(_vm.options[1].id, line).errMessages.length > 0 ? 'errItem' : '',
                ],attrs:{"disabled":!_vm.writable,"maxLength":_vm.options[1].max,"value":!_vm.isLog ? _vm.optionData(_vm.options[1].id, line).field_answer_text
                : _vm.logData[`${_vm.fieldId}_${_vm.options[1].id}_${line}`].field_answer_text,"placeholder":_vm.options[1].placeholder},on:{"input":function($event){return _vm.kickSetText($event, _vm.options[1].id, line)}}}):_vm._e(),(!_vm.isLog && !_vm.isOld)?_c('div',{staticClass:"ml-1"},_vm._l((_vm.optionData(_vm.options[1].id, line).errMessages),function(msg,index){return _c('p',{key:`special3_err1_${index}`,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()],1)]),_c('div',{staticClass:"mt-2 flex"},[_c('div',{class:[
                'mr-20px mt-10px',
                'option-template-' + _vm.options[2].view_template,
              ]},[_c('span',{staticClass:"ml-1 mb-1"},[_vm._v(_vm._s(_vm.options[2].label))]),(_vm.options[2].input_type===_vm.tableInputTypes.TEXT ||
                  _vm.options[2].input_type===_vm.tableInputTypes.NUMBER)?_c('b-form-input',{class:[
                  !_vm.isLog && _vm.optionData(_vm.options[2].id, line).errMessages.length > 0 ? 'errItem' : '',
                ],attrs:{"disabled":!_vm.writable,"maxLength":_vm.options[2].max,"value":!_vm.isLog ? _vm.optionData(_vm.options[2].id, line).field_answer_text
                : _vm.logData[`${_vm.fieldId}_${_vm.options[2].id}_${line}`].field_answer_text,"placeholder":_vm.options[2].placeholder},on:{"input":function($event){return _vm.kickSetText($event, _vm.options[2].id, line)}}}):_vm._e(),(!_vm.isLog && !_vm.isOld)?_c('div',{staticClass:"ml-1"},_vm._l((_vm.optionData(_vm.options[2].id, line).errMessages),function(msg,index){return _c('p',{key:`special3_err1_${index}`,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()],1),_c('div',{class:[
                'mr-20px mt-10px',
                'option-template-' + _vm.options[3].view_template,
              ]},[_c('span',{staticClass:"ml-1 mb-1"},[_vm._v(_vm._s(_vm.options[3].label))]),_c('b-input-group',[(_vm.options[3].input_type===_vm.tableInputTypes.TEXT ||
                    _vm.options[3].input_type===_vm.tableInputTypes.NUMBER)?_c('b-form-input',{class:[
                    !_vm.isLog && _vm.optionData(_vm.options[3].id, line).errMessages.length > 0 ? 'errItem' : '',
                    'inputTemplate_' + _vm.options[3].view_template,
                  ],attrs:{"type":_vm.controlInputType(_vm.options[3].rule_id_list),"formatter":_vm.controlFormatter(_vm.options[3].rule_id_list),"disabled":!_vm.writable,"maxLength":_vm.options[3].max,"value":!_vm.isLog ? _vm.optionData(_vm.options[3].id, line).field_answer_text
                  : _vm.logData[`${_vm.fieldId}_${_vm.options[3].id}_${line}`].field_answer_text,"placeholder":_vm.options[3].placeholder},on:{"input":function($event){return _vm.kickSetText($event, _vm.options[3].id, line)}}}):_vm._e(),_c('b-input-group-append',{staticClass:"ml-1 flex flexMiddle"},[_vm._v(" "+_vm._s(_vm.options[3].suffix)+" ")])],1),(!_vm.isLog && !_vm.isOld)?_c('div',{staticClass:"ml-1"},_vm._l((_vm.optionData(_vm.options[3].id, line).errMessages),function(msg,index){return _c('p',{key:`special3_err1_${index}`,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()],1),_c('div',{class:[
                'mr-20px mt-10px',
                'option-template-' + _vm.options[4].view_template,
              ]},[_c('span',{staticClass:"ml-1 mb-1"},[_vm._v(_vm._s(_vm.options[4].label))]),(_vm.options[4].input_type===_vm.tableInputTypes.TEXT ||
                  _vm.options[4].input_type===_vm.tableInputTypes.NUMBER)?_c('b-form-input',{class:[
                  !_vm.isLog && _vm.optionData(_vm.options[4].id, line).errMessages.length > 0 ? 'errItem' : '',
                  'inputTemplate_' + _vm.options[4].view_template,
                ],attrs:{"type":_vm.controlInputType(_vm.options[4].rule_id_list),"formatter":_vm.controlFormatter(_vm.options[4].rule_id_list),"disabled":!_vm.writable,"maxLength":_vm.options[4].max,"value":!_vm.isLog ? _vm.optionData(_vm.options[4].id, line).field_answer_text
                : _vm.logData[`${_vm.fieldId}_${_vm.options[4].id}_${line}`].field_answer_text,"placeholder":_vm.options[4].placeholder},on:{"input":function($event){return _vm.kickSetText($event, _vm.options[4].id, line)}}}):_vm._e(),(!_vm.isLog && !_vm.isOld)?_c('div',{staticClass:"ml-1"},_vm._l((_vm.optionData(_vm.options[3].id, line).errMessages),function(msg,index){return _c('p',{key:`special3_err1_${index}`,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()],1)])])]),(_vm.maxAnswerNums !== line)?_c('hr'):_vm._e()])}),0),(_vm.writable)?_c('div',{staticClass:"flex mt-2 adjust-left"},[_c('b-button',{staticClass:"addBtn",attrs:{"disabled":!_vm.writable || !(this.maxAnswerNums < this.max_input_number),"variant":"outline-primary"},on:{"click":_vm.addTr}},[_c('img',{staticClass:"plus",attrs:{"src":require("@/assets/svg/plus.svg")}}),_vm._v(" 入力欄を追加 ")])],1):_vm._e(),(_vm.writable && _vm.dicision)?_c('p',{staticClass:"mb-0 mt-2"},[_vm._m(1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10px"},[_c('span',{staticClass:"deleteText mt-10px"},[_vm._v("削除")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"f-13 fix-msg"},[_vm._v("¶決定している場合は、"),_c('span',{staticClass:"red-text"},[_vm._v("「決定」")]),_vm._v("にチェックを入れてください。")])
}]

export { render, staticRenderFns }