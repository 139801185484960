
const collaborator = 1;
const participant = 2;
const destination = 3;
const iterm = 4;

const COMPONENT_NAMES = {
  [collaborator]: 'v-app-collaborator',
  [participant]: 'v-app-participant',
  [destination]: 'v-app-destination',
  [iterm]: 'v-app-i-term',
};

export default {
  COMPONENT_NAMES,
};
