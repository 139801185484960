<template>
  <div class="my-2">
    <b-row class="inputGroup">
      <b-col class="yearWrap year px-0">
        <b-form-input
          type="tel"
          :disabled="!writable"
          :value="date.year"
          :class="[
            'h-50px',
            !isLog && optionData(options[0].id).errMessages.length > 0 ? 'errItem' : '',
          ]"
          :formatter="onlyHalfSizeNumber"
          @input="inputValue($event, 'year')"
          maxlength="4"
          :placeholder="nonePlace ? '':'例）1970'"/>
      </b-col>
      <span>年</span>
      <b-col class="monthWrap">
        <b-form-select
          :disabled="!writable"
          :options="monthOption"
          :value="date.month"
          @input="inputValue($event, 'month')"
          :class="[
            'selectDate',
            !isLog && optionData(options[0].id).errMessages.length > 0 ? 'errItem' : '',
          ]"
        ></b-form-select>
      </b-col>
      <span>月</span>
      <b-col class="dayWrap">
        <b-form-select
          :disabled="!writable"
          :options="dayOption"
          :value="date.day"
          @input="inputValue($event, 'day')"
          :class="[
            'selectDate',
            !isLog && optionData(options[0].id).errMessages.length > 0 ? 'errItem' : '',
          ]"
        ></b-form-select>
      </b-col>
      <span>日</span>
    </b-row>
    <b-row
      v-if="!isOld && !isLog">
      <p class="error my-1"
        v-for="(msg, index) in optionData(options[0].id).errMessages" :key="index">
        {{msg}}
      </p>
    </b-row>
  </div>
</template>
<script>
import moment from 'moment';
import TimeOptionHelper from '@/modules/TimeSelectBoxOption';

export default {
  name: 'PartsApplicationDatetime',
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    isOld: { type: Boolean, default: false },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
    controlFormatter: { type: Function },
    controlInputType: { type: Function },
    nonePlace: { type: Boolean, default: false },
  },
  data() {
    return {
      date: {
        year: '',
        month: '',
        day: '',
      },
    };
  },
  methods: {
    onlyHalfSizeNumber(value) {
      return value.replace(/[^0-9]/g, '');
    },
    setInitValue() {
      let targetInputs = [];
      if (!this.isOld) {
        targetInputs = this.$store.state.gasApplicationInput.inputs;
      } else {
        targetInputs = this.$store.state.gasApplicationOldInput.inputs;
      }
      const initData = targetInputs.find((input) => {
        const sameField = input.field_id === this.fieldId;
        return sameField;
      });
      if (initData.field_answer_text) {
        const value = moment(initData.field_answer_text).format('YYYY/MM/DD');
        [this.date.year, this.date.month, this.date.day] = value.split('/');
      }
    },
    setLogValue() {
      const answerText = this.logData[`${this.fieldId}_${this.options[0].id}_1`].field_answer_text;
      if (answerText) {
        [this.date.year, this.date.month, this.date.day] = answerText.split('/');
        if (!this.date.year || !this.date.month || !this.date.day) {
          [this.date.year, this.date.month, this.date.day] = answerText.split('-');
        }
      }
    },
    kickSetText(value, optionId) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1,
        value,
      };
      this.$emit('kickSetText', param);
    },
    inputValue(value, key) {
      this.date[key] = value;
      if (this.date.year && this.date.month && this.date.day) {
        const dateValue = `${this.date.year}/${this.date.month}/${this.date.day}`;
        this.kickSetText(dateValue, this.options[0].id);
      }
    },
  },
  computed: {
    monthOption() {
      return TimeOptionHelper.getMonthOptions();
    },
    dayOption() {
      return TimeOptionHelper.getDayOptions();
    },
    inputs() {
      if (!this.isOld) {
        return this.$store.state.gasApplicationInput.inputs;
      }
      return this.$store.state.gasApplicationOldInput.inputs;
    },
    optionData() {
      const self = this;
      return (optionId) => {
        const optionData = self.inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          const sameAnswerNo = input.answer_no === 1;
          return sameField && sameOption && sameAnswerNo;
        });
        return optionData;
      };
    },
  },
  created() {
    if (!this.isLog) {
      // 通常時、計画変更時の初期値設定。
      this.setInitValue();
    } else {
      this.setLogValue();
    }
  },
};
</script>

<style scoped>
/* 共通Style */
* {
  color: #333333;
}
.errItem {
  border:solid 1px #dc3545 !important;
}
.itemWrap{
  margin:15px 0;
  width: 800px;
}
.inputGroup{
  align-items: center;
  margin:0 auto;
}
.selectDate{
  width: 90px;
  height: 50px;
}
.labelWrap {
  max-width: 300px;
  font-weight: bold;
  white-space:pre-line;
}
.year input[type="text"]{
  width: 120px;
}
input[type="text"]{
  width: 90px;
  height: 50px;
  margin: 0;
}
.labelWrap {
  margin-right: 0;
  padding-right: 0;
  text-align: left;
}
.labelWrap .badgeWrap,
.labelWrap .labelText{
  display: inline-block;
}
.labelWrap .badgeWrap div{
  vertical-align: middle;
}
label{
  align-items:center;
  margin:0;
  text-align: left;
  width: 220px;
}
span.error{
  padding-left: 31.5%;
  text-align: left;
}

.yearWrap {
  max-width: 130px;
  display: table;
}

.monthWrap, .dayWrap{
  max-width: 110px;
  display: table;
}
.textWrap:not(.year){
  padding-left: 10px;
}
.errItem input{
  border:solid 1px #dc3545;
}
.error{
  font-size: 12.8px;
  color: #dc3545;
  display: block;
  width: 582px;
  margin-left: 18px;
  margin-top: 3px;
}
</style>
