<template>
  <div>
    <component
      :is="`v-text-template-${view_template}`"
      :fieldId='fieldId'
      :options.sync="options"
      :notice="notice"
      :writable="writable"
      :view_template="view_template"
      :rules="rules"
      :isOld="isOld"
      :params="params"
      :isLog="isLog"
      :logData="logData"
      :controlFormatter="controlFormatter"
      :controlInputType="controlInputType"
      :isChangePlan="isChangePlan"
      @kickSetText="kickSetText($event)"
      @kickSetSelect="kickSetSelect($event)"
      @kickSetFile="kickSetFile($event)"
      />

    <b-row
      v-if="notice"
      class="ml-1">
      <b-col class="px-0">
        <p class="mb-0 f-13">{{notice}}</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Template1 from '@/components/parts/application/texts/template_1.vue';
import Template2 from '@/components/parts/application/texts/template_2.vue';
import Template5 from '@/components/parts/application/texts/template_5.vue';

export default {
  name: 'PartsApplicationInput',
  components: {
    'v-text-template-1': Template1,
    'v-text-template-2': Template2,
    'v-text-template-5': Template5,
  },
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    view_template: { type: Number, default: 1 },
    rules: { type: [Object, Array], fedault: () => {} },
    isOld: { type: Boolean, default: false },
    params: { type: [Object, Array], default: () => [] },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
    controlFormatter: { type: Function },
    controlInputType: { type: Function },
    isChangePlan: { type: Boolean, default: false },
  },
  data() {
    return {
    };
  },
  methods: {
    kickSetText(param) {
      this.$emit('kickSetText', param);
    },
    kickSetSelect(param) {
      this.$emit('kickSetSelect', param);
    },
  },
  computed: {
  },
};
</script>

<style scoped>
.errItem {
  border:solid 1px #dc3545 !important;
}
.error {
  font-size: 80%;
  color: #dc3545;
}
input, select {
  height: 50px;
}

/* 長 */
.template_1 {}

/* 中 */
.template_2 {
  width: 400px;
  max-width: 400px;
}

/* 短 */
.template_3 {
  width: 220px;
  max-width: 220px;
}


.optionLabel.label_1 {
  width: 55px;
}

.optionLabel.label_2 {
  width: 160px;
}

.error_1 {
  margin-left: 60px;
}

.error_2 {
  margin-left: 165px;
}
</style>
