var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"my-2 ml-1"},[_c('b-row',[_c('b-col',{staticClass:"px-0"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"mr-2 flex flexMiddle"},[(option.label)?_c('span',{class:[
                'optionLabel',
                'mr-1',
                `label_${_vm.view_template}`
              ]},[_vm._v(_vm._s(option.label))]):_vm._e(),_vm._v(" "+_vm._s(option.prefix)+" ")]),(option.input_type === 1 || option.input_type === 4)?_c('b-form-input',{class:[
              !_vm.isLog && _vm.optionData(option.id).errMessages.length > 0 ||
                (_vm.ltTarget(option.id) && !_vm.lt)
                ? 'errItem' : '',
              `template_${option.view_template}`
            ],attrs:{"type":_vm.controlInputType(option.rule_id_list),"disabled":!_vm.writable,"maxLength":option.max,"inputMode":"verbatim","value":!_vm.isLog ? _vm.optionData(option.id).field_answer_text
              : _vm.logData[`${_vm.fieldId}_${option.id}_1`].field_answer_text,"formatter":_vm.controlFormatter(option.rule_id_list),"placeholder":option.placeholder},on:{"input":function($event){return _vm.kickSetText($event, option.id)}}}):_vm._e(),(option.input_type === 2)?_c('b-form-select',{class:[
              !_vm.isLog && _vm.optionData(option.id).errMessages.length > 0 ||
                (_vm.ltTarget(option.id) && !_vm.lt)
                ? 'errItem' : '',
              `template_${option.view_template}`
            ],attrs:{"disabled":!_vm.writable,"value":!_vm.isLog ? _vm.optionData(option.id).field_answer_id
            : _vm.logData[`${_vm.fieldId}_${option.id}_1`].field_answer_id,"options":option.choice_texts},on:{"input":function($event){return _vm.kickSetSelect($event, option.id)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{domProps:{"value":null}},[_vm._v("-- 選択してください。 --")])]},proxy:true}],null,true)}):_vm._e(),_c('b-input-group-append',{staticClass:"ml-2 flex flexMiddle"},[_vm._v(" "+_vm._s(option.suffix)+" ")])],1)],1)],1),(!_vm.isOld && !_vm.isLog)?_c('b-row',{class:[option.label ? `error_${_vm.view_template}` : 'ml-1']},[_vm._l((_vm.optionData(option.id).errMessages),function(msg,index){return _c('p',{key:index,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),_c('p',{directives:[{name:"show",rawName:"v-show",value:((_vm.ltTarget(option.id) && !_vm.lt)),expression:"(ltTarget(option.id) && !lt)"}],staticClass:"error my-1"},[_vm._v(_vm._s(_vm.ltMsg))])],2):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }