<template>
<!-- readonlyの自動計算用 -->
  <div>
    <div
      class="my-2 ml-1">
      <b-row>
        <div
          v-for="(option, index) in options" :key="index"
          class="px-0">
          <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle">
              <span
                v-if="option.label"
                :class="[
                  'optionLabel',
                  'mr-1',
                  `label_${view_template}`
                ]">{{option.label}}</span>
              {{option.prefix}}
            </b-input-group-prepend>
            <!-- テキストと数値 -->
            <b-form-input
              :type="controlInputType(option.rule_id_list)"
              readonly
              :disabled="!writable"
              :class="[
                !isLog && optionData(option.id).errMessages.length > 0 ||
                  (ltTarget(option.id) && !lt)
                  ? 'errItem' : '',
                `template_${option.view_template}`
              ]"
              :maxLength="option.max"
              :value="!isLog ? optionData(option.id).field_answer_text
                : logData[`${fieldId}_${option.id}_1`].field_answer_text"
              :formatter="controlFormatter(option.rule_id_list)"
              @input="kickSetText($event, option.id)"
              :placeholder="option.placeholder"/>
            <b-input-group-append
              class="ml-2 flex flexMiddle">
              {{option.suffix}}
            </b-input-group-append>
          </b-input-group>
        </div>
      </b-row>
      <b-row
        v-if="!isOld && !isLog">
        <div
          :class="[option.label ? `error_${view_template}` : 'ml-1']"
          v-for="(option, index) in options" :key="index">
          <p class="error my-1"
            v-for="(msg, index) in optionData(option.id).errMessages" :key="index">
            {{msg}}
          </p>
          <p class="error my-1"
            v-show="(ltTarget(option.id) && !lt)">{{ltMsg}}</p>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'PartsApplicationTextsTemplate5',
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    view_template: { type: Number, default: 1 },
    rules: { type: [Object, Array], fedault: () => {} },
    isOld: { type: Boolean, default: false },
    params: { type: [Object, Array], default: () => [] },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
    controlFormatter: { type: Function },
    controlInputType: { type: Function },
    isChangePlan: { type: Boolean, default: false },
  },
  data() {
    return {
    };
  },
  created() {
    if (!this.isChangePlan || this.isLog) {
      return;
    }
    let yearValue = '';
    let monthValue = '';
    if (this.calcYmdList[0] && this.calcYmdList[1] && moment(this.calcYmdList[0], 'YYYY/MM/DD', true).isValid() && moment(this.calcYmdList[1], 'YYYY/MM/DD', true).isValid()) {
      const dateFrom = moment(this.calcYmdList[0]);
      // to は、翌日で考える。3/31と入力された場合に、3/31の0時ではなく24時とするため。
      const dateTo = moment(this.calcYmdList[1]).add(1, 'd');
      monthValue = dateTo.diff(dateFrom, 'months');
      yearValue = Math.floor(monthValue / 12);
      monthValue -= yearValue * 12;
    }
    this.kickSetText(yearValue, this.options[0].id);
    this.kickSetText(monthValue, this.options[1].id);
  },
  methods: {
    kickSetText(value, optionId) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1, // テキストは当面１つ固定
        value,
      };
      this.$emit('kickSetText', param);
    },
    kickSetSelect(value, optionId) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1, // テキストは当面１つ固定
        value,
      };
      this.$emit('kickSetSelect', param);
    },
  },
  computed: {
    inputs() {
      if (this.isLog) {
        return [];
      }
      if (!this.isOld) {
        return this.$store.state.gasApplicationInput.inputs;
      }
      return this.$store.state.gasApplicationOldInput.inputs;
    },
    optionData() {
      const self = this;
      return (optionId) => {
        const optionData = self.inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          const sameAnswerNo = input.answer_no === 1;
          return sameField && sameOption && sameAnswerNo;
        });
        return optionData;
      };
    },
    lt_column() {
      return this.rules.lt_column;
    },
    sum() {
      let sum = '';
      if (this.rules.lt_column) {
        const optionId = this.rules.lt_column.option_id;
        sum = 0;
        this.inputs.forEach((input) => {
          const sameOption = input.option_id === optionId;
          if (sameOption) {
            sum += Number(input.field_answer_text);
          }
        });
      }
      return sum;
    },
    lt() {
      let result = true;
      let targetValue = '';
      if (this.rules.lt_column) {
        const optionId = Number(this.lt_column.params[1].parameter);
        const targetOption = this.inputs.find((input) => {
          const sameOption = input.option_id === optionId;
          const sameAnswer = input.answer_no === 1;
          return sameOption && sameAnswer;
        });
        targetValue = Number(targetOption.field_answer_text);
        result = targetValue >= this.sum;
      }
      return result;
    },
    ltTarget() {
      return (optionId) => {
        if (typeof this.lt_column === 'undefined') {
          return false;
        }
        return this.lt_column.option_id === optionId;
      };
    },
    ltMsg() {
      let msg = '';
      if (this.rules.lt_column) {
        const data = this.$store.state.gasApplicationForm.optionDataList;
        // const { fieldLabel, optionLabel } = data[this.lt_column.params[1].parameter];
        const { optionLabel } = data[this.lt_column.params[1].parameter];
        msg = `${optionLabel}以下の値を入力してください。`;
      }
      return msg;
    },
    calcValue() {
      // 0は識別番号。1が割れる数を示す。2は割る数。1は割られる数のフィールドID、2は割る数のオプションID
      if ((!this.params[0] || this.params[0].param !== 2) || !this.params[1] || !this.params[2]) {
        return null;
      }
      let result = 0;
      let sum = 0;
      const sumFieldId = this.params[1].param;
      this.inputs.forEach((input) => {
        // eslint-disable-next-line
        if (input.field_id === sumFieldId && input.field_answer_text && !isNaN(input.field_answer_text)) {
          sum += Number(input.field_answer_text);
        }
      });

      let waru = 0;
      const waruFieldId = this.params[2].param;
      this.inputs.forEach((input) => {
        // eslint-disable-next-line
        if (input.field_id === waruFieldId && input.field_answer_text && !isNaN(input.field_answer_text)) {
          waru += Number(input.field_answer_text);
        }
      });

      if (waru !== 0) {
        result = sum / waru;
      }

      return Math.round(result);
    },
    calcYmdList() {
      // 0は識別番号。1がフィールドID
      if ((!this.params[0] || this.params[0].param !== 3) || !this.params[1]) {
        return null;
      }
      const ymdList = [];
      const fieldId = this.params[1].param;
      this.inputs.forEach((input) => {
        if (input.field_id === fieldId) {
          ymdList.push(input.field_answer_text);
        }
      });
      return ymdList;
    },
  },
  watch: {
    calcValue(calcValue) {
      if (this.isLog) {
        return;
      }
      this.kickSetText(calcValue, this.options[0].id);
    },
    // 切り捨て
    calcYmdList(calcYmdList) {
      if (this.isLog) {
        return;
      }
      let yearValue = '';
      let monthValue = '';
      if (calcYmdList[0] && calcYmdList[1] && moment(calcYmdList[0], 'YYYY/MM/DD', true).isValid() && moment(calcYmdList[1], 'YYYY/MM/DD', true).isValid()) {
        const dateFrom = moment(calcYmdList[0]);
        // to は、翌日で考える。3/31と入力された場合に、3/31の0時ではなく24時とするため。
        const dateTo = moment(calcYmdList[1]).add(1, 'd');
        monthValue = dateTo.diff(dateFrom, 'months');
        yearValue = Math.floor(monthValue / 12);
        monthValue -= yearValue * 12;
      }
      this.kickSetText(yearValue, this.options[0].id);
      this.kickSetText(monthValue, this.options[1].id);
    },
  },
};
</script>

<style scoped>
.errItem {
  border:solid 1px #dc3545 !important;
}
.error {
  font-size: 80%;
  color: #dc3545;
}
input, select {
  height: 50px;
}

/* 長 */
.template_1 {}

/* 中 */
.template_2 {
  width: 400px;
  max-width: 400px;
}

/* 短 */
.template_3 {
  width: 220px;
  max-width: 220px;
}

/* 極短 */
.template_4 {
  width: 120px;
  max-width: 120px;
}

.optionLabel.label_1 {
  width: 120px;
  font-weight: bold;
}

.optionLabel.label_2 {
  width: 160px;
}

.error_1 {
  margin-left: 125px;
}

.error_2 {
  margin-left: 165px;
}
</style>
