var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"inlineBlock positionRelative"},[_c('v-calendar',{ref:"picker",class:[
        !_vm.isLog && _vm.optionData(_vm.options[0].id).errMessages.length > 0 ? 'errItem' : '',
      ],attrs:{"disabled":!_vm.writable,"input-class":'calendar app-parts-calendar',"value":!_vm.isLog ? _vm.optionData(_vm.options[0].id).field_answer_text
      : _vm.logData[`${_vm.fieldId}_${_vm.options[0].id}_1`].field_answer_text,"format":_vm.options[0].input_type === 12 ? _vm.DatePickerFormat2 : _vm.DatePickerFormat,"language":_vm.ja,"typeable":true,"calendar-button":true,"calendar-button-icon":"event-from","minimum-view":_vm.options[0].input_type === 12 ? 'month': 'day'},on:{"selected":function($event){return _vm.kickSetText($event, _vm.options[0].id, 1)}}}),_c('unicon',{staticClass:"app-parts-calendar-icon-from",attrs:{"name":"calendar-alt","fill":"#495057"}})],1),_vm._v(" ～ "),_c('div',{staticClass:"inlineBlock positionRelative"},[_c('v-calendar',{ref:"picker",class:[
        !_vm.isLog && _vm.optionData(_vm.options[1].id).errMessages.length > 0 ? 'errItem' : '',
      ],attrs:{"disabled":!_vm.writable,"input-class":'calendar app-parts-calendar',"value":!_vm.isLog ? _vm.optionData(_vm.options[1].id).field_answer_text
      : _vm.logData[`${_vm.fieldId}_${_vm.options[1].id}_1`].field_answer_text,"format":_vm.options[1].input_type === 12 ? _vm.DatePickerFormat2 : _vm.DatePickerFormat,"language":_vm.ja,"typeable":true,"calendar-button":true,"calendar-button-icon":"event-to","minimum-view":_vm.options[1].input_type === 12 ? 'month': 'day'},on:{"selected":function($event){return _vm.kickSetText($event, _vm.options[1].id, 2)}}}),_c('unicon',{staticClass:"app-parts-calendar-icon-to",attrs:{"name":"calendar-alt","fill":"#495057"}})],1),(!_vm.isOld && !_vm.isLog)?_c('div',[_vm._l((_vm.optionData(_vm.options[0].id).errMessages),function(msg,index){return _c('div',{key:`0_${index}`,staticClass:"error my-1 ml-2"},[_vm._v(" "+_vm._s(msg)+" ")])}),_vm._l((_vm.optionData(_vm.options[1].id).errMessages),function(msg,index){return _c('div',{key:`1_${index}`,staticClass:"error my-1 ml-2"},[_vm._v(" "+_vm._s(msg)+" ")])})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }