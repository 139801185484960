<template>
  <div  class="mt-10px special-3-wrap">
    <div
      class="my-2 ml-4">
      <div v-for="(line) of maxAnswerNums" :key="line"
        class="groupInputs mt-2 mb-3">
        <div class="mt-10px">
          <span class="deleteText mt-10px">削除</span>
        </div>
        <div class="deleteBtn">
          <img
            src="@/assets/svg/close.svg"
            v-if="writable"
            @click="removeTr(line)"
            class="tdDeleteBtn">
        </div>

        <div class="flex flexMiddle mx-2">
          <div
            v-if="dicision"
            class="mr-2">
            決定
            <b-form-checkbox
              class="gas-form-checkbox-parts checkbox-ccc ml-1 dicisiton-check"
              :disabled="!writable"
              :name="`check${line}`"
              :checked="checkedState(options[5].id, line)"
              :value="true"
              @change="kickSetText($event, options[5].id, line)"
              switches>
            </b-form-checkbox>
          </div>

          <div class="input-area">
            <!-- 一列目 -->
            <div class="flex">
              <div
                :class="[
                  'mr-20px mt-10px',
                  'option-template-' + options[0].view_template,
                ]">
                <span class="ml-1 mb-1">{{options[0].label}}</span>
                <b-form-input
                  :disabled="!writable"
                  :class="[
                    !isLog && optionData(options[0].id, line).errMessages.length > 0 ? 'errItem' : '',
                    'inputTemplate_' + options[0].view_template,
                  ]"
                  :maxLength="options[0].max"
                  :value="!isLog ? optionData(options[0].id, line).field_answer_text
                  : logData[`${fieldId}_${options[0].id}_${line}`].field_answer_text"
                  @input="kickSetText($event, options[0].id, line)"
                  v-if="options[0].input_type===tableInputTypes.TEXT ||
                    options[0].input_type===tableInputTypes.NUMBER"
                  :placeholder="options[0].placeholder"/>
                <div
                  v-if="!isLog && !isOld"
                  class="ml-1">
                  <p
                    v-for="(msg, index) in optionData(options[0].id, line).errMessages" :key="`special3_err0_${index}`"
                    class="error my-1">
                    {{msg}}</p>
                </div>
              </div>
              <div
                :class="[
                  'mr-20px mt-10px',
                  'option-template-' + options[1].view_template,
                ]">
                <span class="ml-1 mb-1">{{options[1].label}}</span>
                <b-form-input
                  :disabled="!writable"
                  :class="[
                    !isLog && optionData(options[1].id, line).errMessages.length > 0 ? 'errItem' : '',
                  ]"
                  :maxLength="options[1].max"
                  :value="!isLog ? optionData(options[1].id, line).field_answer_text
                  : logData[`${fieldId}_${options[1].id}_${line}`].field_answer_text"
                  @input="kickSetText($event, options[1].id, line)"
                  v-if="options[1].input_type===tableInputTypes.TEXT ||
                    options[1].input_type===tableInputTypes.NUMBER"
                  :placeholder="options[1].placeholder"/>
                <div
                  v-if="!isLog && !isOld"
                  class="ml-1">
                  <p
                    v-for="(msg, index) in optionData(options[1].id, line).errMessages" :key="`special3_err1_${index}`"
                    class="error my-1">
                    {{msg}}
                  </p>
                </div>
              </div>
            </div>

            <!-- 二列目 -->
            <div class="mt-2 flex">
              <div
                :class="[
                  'mr-20px mt-10px',
                  'option-template-' + options[2].view_template,
                ]">
                <span class="ml-1 mb-1">{{options[2].label}}</span>
                <b-form-input
                  :disabled="!writable"
                  :class="[
                    !isLog && optionData(options[2].id, line).errMessages.length > 0 ? 'errItem' : '',
                  ]"
                  :maxLength="options[2].max"
                  :value="!isLog ? optionData(options[2].id, line).field_answer_text
                  : logData[`${fieldId}_${options[2].id}_${line}`].field_answer_text"
                  @input="kickSetText($event, options[2].id, line)"
                  v-if="options[2].input_type===tableInputTypes.TEXT ||
                    options[2].input_type===tableInputTypes.NUMBER"
                  :placeholder="options[2].placeholder"/>
                <div
                  v-if="!isLog && !isOld"
                  class="ml-1">
                  <p
                    v-for="(msg, index) in optionData(options[2].id, line).errMessages" :key="`special3_err1_${index}`"
                    class="error my-1">
                    {{msg}}
                  </p>
                </div>
              </div>
              <div
                :class="[
                  'mr-20px mt-10px',
                  'option-template-' + options[3].view_template,
                ]">
                <span class="ml-1 mb-1">{{options[3].label}}</span>
                <b-input-group>
                  <b-form-input
                    :type="controlInputType(options[3].rule_id_list)"
                    :formatter="controlFormatter(options[3].rule_id_list)"
                    :disabled="!writable"
                    :class="[
                      !isLog && optionData(options[3].id, line).errMessages.length > 0 ? 'errItem' : '',
                      'inputTemplate_' + options[3].view_template,
                    ]"
                    :maxLength="options[3].max"
                    :value="!isLog ? optionData(options[3].id, line).field_answer_text
                    : logData[`${fieldId}_${options[3].id}_${line}`].field_answer_text"
                    @input="kickSetText($event, options[3].id, line)"
                    v-if="options[3].input_type===tableInputTypes.TEXT ||
                      options[3].input_type===tableInputTypes.NUMBER"
                    :placeholder="options[3].placeholder"/>
                  <b-input-group-append
                    class="ml-1 flex flexMiddle">
                    {{options[3].suffix}}
                  </b-input-group-append>
                </b-input-group>
                <div
                  v-if="!isLog && !isOld"
                  class="ml-1">
                  <p
                    v-for="(msg, index) in optionData(options[3].id, line).errMessages" :key="`special3_err1_${index}`"
                    class="error my-1">
                    {{msg}}
                  </p>
                </div>
              </div>
              <div
                :class="[
                  'mr-20px mt-10px',
                  'option-template-' + options[4].view_template,
                ]">
                <span class="ml-1 mb-1">{{options[4].label}}</span>
                <b-form-input
                  :type="controlInputType(options[4].rule_id_list)"
                  :formatter="controlFormatter(options[4].rule_id_list)"
                  :disabled="!writable"
                  :class="[
                    !isLog && optionData(options[4].id, line).errMessages.length > 0 ? 'errItem' : '',
                    'inputTemplate_' + options[4].view_template,
                  ]"
                  :maxLength="options[4].max"
                  :value="!isLog ? optionData(options[4].id, line).field_answer_text
                  : logData[`${fieldId}_${options[4].id}_${line}`].field_answer_text"
                  @input="kickSetText($event, options[4].id, line)"
                  v-if="options[4].input_type===tableInputTypes.TEXT ||
                    options[4].input_type===tableInputTypes.NUMBER"
                  :placeholder="options[4].placeholder"/>
                <div
                  v-if="!isLog && !isOld"
                  class="ml-1">
                  <p
                    v-for="(msg, index) in optionData(options[3].id, line).errMessages" :key="`special3_err1_${index}`"
                    class="error my-1">
                    {{msg}}
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>

        <hr v-if="maxAnswerNums !== line">
      </div>
    </div>

    <div class="flex mt-2 adjust-left"
      v-if="writable">
      <b-button
      class="addBtn"
      :disabled="!writable || !(this.maxAnswerNums < this.max_input_number)"
      @click="addTr"
      variant="outline-primary">
        <img class="plus" src="@/assets/svg/plus.svg">
        入力欄を追加
      </b-button>
    </div>
    <p
      v-if="writable && dicision"
      class="mb-0 mt-2">
      <span class="f-13 fix-msg">¶決定している場合は、<span class="red-text">「決定」</span>にチェックを入れてください。</span>
    </p>
  </div>
</template>
<script>
import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'PartsApplicationSpecialJDestination',
  props: {
    fieldId: { type: Number },
    is_variable: { type: [Boolean, Number], default: true },
    input_number: { type: Number, default: 1 },
    min_input_number: { type: Number, default: 1 },
    max_input_number: { type: Number, default: 1 },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    view_template: { type: Number, default: 1 },
    rules: { type: [Object, Array], fedault: () => {} },
    params: { type: [Object, Array], default: () => [] },
    isOld: { type: Boolean, default: false },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
    controlFormatter: { typr: Function },
    controlInputType: { type: Function },
  },
  data() {
    return {
    };
  },
  methods: {
    addTr() {
      if (this.maxAnswerNums < this.max_input_number) {
        // データ上で入力項目の数を追加し、入力欄を追加する
        const newLineNum = this.maxAnswerNums + 1;
        const addParam = this._getInputParameter(newLineNum);
        const ansNumParam = {
          fieldId: this.fieldId,
          newLineNum,
        };
        this.$store.commit('gasApplicationInput/setMaxAnswerNums', ansNumParam);
        this.$store.commit('gasApplicationInput/addInputs', addParam);
      }
    },
    removeTr(line) {
      if (this.maxAnswerNums > this.min_input_number) {
        // 末尾の入力欄を削除し、入力項目の数も１つ減らす
        const removeParam = {
          fieldId: this.fieldId,
          line,
        };
        const newLineNum = this.maxAnswerNums - 1;
        const ansNumParam = {
          fieldId: this.fieldId,
          newLineNum,
        };
        this.$store.commit('gasApplicationInput/removeInputs', removeParam);
        this.$store.commit('gasApplicationInput/setMaxAnswerNums', ansNumParam);
      }
    },
    _getInputParameter(newLineNum) {
      const params = [];
      const commonParam = {
        field_id: this.fieldId,
        answer_no: newLineNum,
      };
      this.options.forEach((option) => {
        const param = Object.assign({}, commonParam);
        param.option_id = option.id;
        // チェックボックスの時、追加の初期値パラメータを渡す
        if (option.input_type === 11) {
          param.init_value = 0;
          param.input_type = 11;
        }
        params.push(param);
      });
      return params;
    },
    kickSetText(value, optionId, line) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: line,
        value,
      };
      this.$emit('kickSetText', param);
    },
  },
  computed: {
    inputs() {
      if (this.isLog) {
        return [];
      }
      if (!this.isOld) {
        return this.$store.state.gasApplicationInput.inputs;
      }
      return this.$store.state.gasApplicationOldInput.inputs;
    },
    dicision() {
      const optionId = this.params[0].param;
      const answerId = this.params[1].param;
      const optionData = this.inputs.find((input) => {
        const sameOption = input.option_id === optionId;
        return sameOption;
      });
      if (!optionData) {
        return false;
      }
      return Number(optionData.field_answer_id) === answerId;
    },
    maxAnswerNums() {
      if (!this.isOld && !this.isLog) {
        return this.$store.state.gasApplicationInput.maxAnswerNums[this.fieldId];
      }
      return this.$store.state.gasApplicationOldInput.maxAnswerNums[this.fieldId];
    },
    tableInputTypes() {
      return CONST_OPTIONS.TABLE_INPUT_TYEPS;
    },
    optionData() {
      const self = this;
      return (optionId, line) => {
        const optionData = self.inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          const sameAnswerNo = input.answer_no === line;
          return sameField && sameOption && sameAnswerNo;
        });
        if (typeof optionData === 'undefined') {
          return [];
        }
        return optionData;
      };
    },
    checkedState() {
      return (optionId, line) => {
        let value = '';
        if (!this.isLog) {
          value = this.optionData(optionId, line).field_answer_text;
        } else {
          value = this.logData[`${this.fieldId}_${optionId}_${line}`].field_answer_text;
        }
        return value === '1' || value === 1 || value === true;
      };
    },
  },
  watch: {
    dicision() {
      // 決定にした場合、1つ目以外の回答を全部削除
      // if (this.dicision) {
      //   const deleteLineNo = this.maxAnswerNums;
      //   // 最後の一行になるまで消し続ける
      //   for (let i = deleteLineNo; i > 1; i += -1) {
      //     this.removeTr(i);
      //   }
      // 未決定にした場合、すべてのチェックボックスに値falseをいれる
      if (!this.dicision) {
        const optionData = this.inputs.filter((input) => {
          const sameField = input.field_id === this.fieldId;
          const sameOption = input.option_id === this.options[5].id;
          return sameField && sameOption;
        });
        optionData.forEach((data) => {
          const param = {
            field_id: this.fieldId,
            option_id: data.option_id,
            answer_no: data.answer_no,
            value: false,
          };
          this.$emit('kickSetText', param);
        });
      }
    },
  },
  created() {
  },
};
</script>

<style scoped>
  .special-3-wrap {
    margin-left: -27px;
  }

  .deleteText {
    position: absolute;
    right: 10px;
  }
  .deleteBtn {
    position: absolute;
    right: 10px;
    transform: translateY(285%);
    cursor: pointer;
  }
  .groupInputs {
    width: 750px;
  }

  .errItem {
    border:solid 1px #dc3545 !important;
  }
  .error {
    font-size: 80%;
    color: #dc3545;
    /* bootstrapで太字が当てられていたので。 */
    font-weight: normal;
  }

  input, select {
    height: 50px;
  }

  .addBtn {
    color:#293CB0 !important;
    border-color: #293CB0 !important;
    border-radius: 30px;
    width: 200px;
  }

  .plus {
    margin-bottom: 4px;
  }
  .closeTh {
    padding-top:20px;
  }

  .btn-outline-primary:hover {
    background-color: #fff;
    opacity: 0.6;
  }

  .mr-20px {
    margin-right: 20px;
  }

  .mt-10px {
    margin-top: 10px;
  }

  /* 中 */
  .option-template-1 {
    width: 680px;
    max-width: 680px;
  }
  .option-template-2 {
    width: 330px;
    max-width: 330px;
  }
  .inputTemplate-4 {
    width: 135px;
    max-width: 135px;
  }

  .adjust-left {
    margin-left: 27px;
  }

  .input-area {
    margin-left: -5px;
  }

  .dicisiton-check {
    width: 40px;
  }

  .fix-msg {
    margin-left: 26px;
  }
</style>
