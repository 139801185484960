var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-2"},[_c('b-row',{staticClass:"inputGroup"},[_c('b-col',{staticClass:"yearWrap year px-0"},[_c('b-form-input',{class:[
          'h-50px',
          !_vm.isLog && _vm.optionData(_vm.options[0].id).errMessages.length > 0 ? 'errItem' : '',
        ],attrs:{"type":"tel","disabled":!_vm.writable,"value":_vm.date.year,"formatter":_vm.onlyHalfSizeNumber,"maxlength":"4","placeholder":_vm.nonePlace ? '':'例）1970'},on:{"input":function($event){return _vm.inputValue($event, 'year')}}})],1),_c('span',[_vm._v("年")]),_c('b-col',{staticClass:"monthWrap"},[_c('b-form-select',{class:[
          'selectDate',
          !_vm.isLog && _vm.optionData(_vm.options[0].id).errMessages.length > 0 ? 'errItem' : '',
        ],attrs:{"disabled":!_vm.writable,"options":_vm.monthOption,"value":_vm.date.month},on:{"input":function($event){return _vm.inputValue($event, 'month')}}})],1),_c('span',[_vm._v("月")]),_c('b-col',{staticClass:"dayWrap"},[_c('b-form-select',{class:[
          'selectDate',
          !_vm.isLog && _vm.optionData(_vm.options[0].id).errMessages.length > 0 ? 'errItem' : '',
        ],attrs:{"disabled":!_vm.writable,"options":_vm.dayOption,"value":_vm.date.day},on:{"input":function($event){return _vm.inputValue($event, 'day')}}})],1),_c('span',[_vm._v("日")])],1),(!_vm.isOld && !_vm.isLog)?_c('b-row',_vm._l((_vm.optionData(_vm.options[0].id).errMessages),function(msg,index){return _c('p',{key:index,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }