var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10px"},[_c('div',{staticClass:"my-2"},_vm._l((_vm.maxAnswerNums[_vm.fieldId]),function(line){return _c('div',{key:line,staticClass:"groupInputs mt-2 mb-3"},[_vm._m(0,true),_c('div',{staticClass:"deleteBtn"},[(_vm.writable)?_c('img',{staticClass:"tdDeleteBtn",attrs:{"src":require("@/assets/svg/close.svg")},on:{"click":function($event){return _vm.removeTr(line)}}}):_vm._e()]),_c('div',{staticClass:"input-area"},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"inlineBlock mr-20px mt-10px"},[_c('span',{staticClass:"ml-1 mb-1"},[_vm._v(_vm._s(option.label))]),(option.input_type===_vm.tableInputTypes.TEXT ||
              option.input_type===_vm.tableInputTypes.NUMBER)?_c('b-input-group',[_c('b-form-input',{class:[
              !_vm.isLog && _vm.optionData(option.id, line).errMessages.length > 0 ? 'errItem' : '',
              'inputTemplate_' + option.view_template,
            ],attrs:{"type":_vm.controlInputType(option.rule_id_list),"disabled":!_vm.writable,"maxLength":option.max,"value":!_vm.isLog ? _vm.optionData(option.id, line).field_answer_text
            : _vm.logData[`${_vm.fieldId}_${option.id}_${line}`].field_answer_text,"formatter":_vm.controlFormatter(option.rule_id_list),"placeholder":option.placeholder},on:{"input":function($event){return _vm.kickSetText($event, option.id, line)}}}),_c('b-input-group-append',{staticClass:"ml-1 flex flexMiddle"},[_vm._v(" "+_vm._s(option.suffix)+" ")])],1):_vm._e(),(option.input_type===_vm.tableInputTypes.SELECT)?_c('b-form-select',{class:[
              !_vm.isLog && _vm.optionData(option.id, line).errMessages.length > 0 ? 'errItem' : '',
              'inputTemplate_' + option.view_template,
              'block',
            ],attrs:{"disabled":!_vm.writable,"value":!_vm.isLog ? _vm.optionData(option.id, line).field_answer_id
            : _vm.logData[`${_vm.fieldId}_${option.id}_${line}`].field_answer_id,"options":option.choice_texts},on:{"input":function($event){return _vm.kickSetSelect($event, option.id, line)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v("-- 選択してください。 --")])]},proxy:true}],null,true)},[_vm._v(" > ")]):_vm._e(),(option.input_type===_vm.tableInputTypes.FIXED)?_c('span',[_vm._v(" "+_vm._s(option.choice_texts)+" ")]):_vm._e(),(!_vm.isLog && !_vm.isOld)?_c('div',{staticClass:"ml-1"},_vm._l((_vm.optionData(option.id, line).errMessages),function(msg,index){return _c('p',{key:index,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()],1)}),0)])}),0),(_vm.writable)?_c('div',{staticClass:"flex adjust-left"},[_c('b-button',{staticClass:"addBtn",attrs:{"disabled":!(this.maxAnswerNums[this.fieldId] < this.max_input_number),"variant":"outline-primary"},on:{"click":_vm.addTr}},[_c('img',{staticClass:"plus",attrs:{"src":require("@/assets/svg/plus.svg")}}),_vm._v(" 入力欄を追加 ")])],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10px"},[_c('span',{staticClass:"deleteText mt-10px"},[_vm._v("削除")])])
}]

export { render, staticRenderFns }