<template>
  <div>
    <div
      class="my-2 ml-1">
      <b-row>
        <b-col class="px-0">
          <b-input-group class="term-select">
            <b-input-group-prepend
              class="mr-2 flex flexMiddle">
            </b-input-group-prepend>
          <!-- セレクトボックス -->
          <b-form-select
            class="h-50px"
            :value="month"
            :disabled="!writable"
            :options="monthOptions"
            @change="setValue($event)"
            :class="[
              !isLog && optionData(options[1].id).errMessages.length > 0 ? 'errItem' : '',
              'inputTemplate_' + options[1].view_template,
            ]"
            >
              <template v-slot:first>
                <option :value="null">---</option>
              </template>
            </b-form-select>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
        v-if="!isLog && !isOld">
        <p class="error my-1"
          v-for="(msg, index) in optionData(options[1].id).errMessages" :key="index">
          {{msg}}
        </p>
      </b-row>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'PartsApplicationSpecialJTerm',
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    view_template: { type: Number, default: 1 },
    rules: { type: [Object, Array], fedault: () => {} },
    isOld: { type: Boolean, default: false },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
  },
  data() {
    return {
      month: null,
    };
  },
  methods: {
    kickSetText(value, optionId) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1,
        value,
      };
      this.$emit('kickSetText', param);
    },
    setValue(value) {
      let from = '';
      let to = '';

      if (value) {
        const nextYear = this.year;
        const fromTime = moment(`${nextYear}-04-01`);
        from = fromTime.format('YYYY-MM-DD');
        to = fromTime.add(value, 'months').subtract(1, 'days').format('YYYY-MM-DD');
      }

      const fromParam = {
        field_id: this.fieldId,
        option_id: this.options[0].id,
        answer_no: 1,
        value: from,
      };
      this.$emit('kickSetText', fromParam);
      const toParam = {
        field_id: this.fieldId,
        option_id: this.options[1].id,
        answer_no: 1,
        value: to,
      };
      this.$emit('kickSetText', toParam);
    },
  },
  computed: {
    monthOptions() {
      return [
        { value: 6, text: '6ヶ月' },
        { value: 12, text: '12ヶ月' },
      ];
    },
    inputs() {
      if (this.isLog) {
        return [];
      }
      if (!this.isOld) {
        return this.$store.state.gasApplicationInput.inputs;
      }
      return this.$store.state.gasApplicationOldInput.inputs;
    },
    optionData() {
      const self = this;
      return (optionId) => {
        const optionData = self.inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          return sameField && sameOption;
        });
        if (typeof optionData === 'undefined') {
          return [];
        }
        return optionData;
      };
    },
    year() {
      return this.$store.state.gasApplicationForm.year;
    },
  },
  created() {
    const self = this;
    if (!this.isLog) {
      const options = self.inputs.filter((input) => {
        const sameField = input.field_id === self.fieldId;
        return sameField;
      });
      if (options[0].field_answer_text !== '' && options[1].field_answer_text !== '') {
        const from = moment(options[0].field_answer_text);
        const to = moment(options[1].field_answer_text).add(1, 'days');
        this.month = to.diff(from, 'months');
      }
    } else {
      const logFrom = this.logData[`${this.fieldId}_${this.options[0].id}_1`].field_answer_text;
      const logTo = this.logData[`${this.fieldId}_${this.options[1].id}_1`].field_answer_text;
      if (logFrom && logTo) {
        const logFromMoment = moment(logFrom);
        const logToMoment = moment(logTo).add(1, 'days');
        this.month = logToMoment.diff(logFromMoment, 'months');
      }
    }
  },
};
</script>

<style scoped>
.errItem {
  border:solid 1px #dc3545 !important;
}
.error {
  font-size: 80%;
  color: #dc3545;
}
input {
  height: 50px;
}
.template_1 {
  width: 220px;
  max-width: 220px;
}

.template_2 {
  width: 400px;
  max-width: 400px;
}

.optionLabel {
  width: 105px;
}

.errorWrap {
  margin-left: 110px;
}

.term-select {
  width: 127px;
}

.error{
  font-size: 12.8px;
  color: #dc3545;
  display: block;
  width: 582px;
  margin-left: 18px;
  margin-top: 3px;
}
</style>
