<template>
  <div class="mt-2">
    <div class="inlineBlock positionRelative">
      <v-calendar
        :disabled="!writable"
        :input-class="'calendar app-parts-calendar'"
        :value="!isLog ? optionData(options[0].id).field_answer_text
        : logData[`${fieldId}_${options[0].id}_1`].field_answer_text"
        @selected="kickSetText($event, options[0].id, 1)"
        :format="options[0].input_type === 12 ? DatePickerFormat2 : DatePickerFormat"
        :language="ja"
        :typeable="true"
        ref="picker"
        :calendar-button="true"
        calendar-button-icon="event-from"
        :minimum-view="options[0].input_type === 12 ? 'month': 'day'"
        :class="[
          !isLog && optionData(options[0].id).errMessages.length > 0 ? 'errItem' : '',
        ]"
      />
      <unicon name="calendar-alt"
        fill="#495057"
        class="app-parts-calendar-icon-from"
        ></unicon>
    </div>
    ～
    <div class="inlineBlock positionRelative">
      <v-calendar
        :disabled="!writable"
        :input-class="'calendar app-parts-calendar'"
        :value="!isLog ? optionData(options[1].id).field_answer_text
        : logData[`${fieldId}_${options[1].id}_1`].field_answer_text"
        @selected="kickSetText($event, options[1].id, 2)"
        :format="options[1].input_type === 12 ? DatePickerFormat2 : DatePickerFormat"
        :language="ja"
        :typeable="true"
        ref="picker"
        :calendar-button="true"
        calendar-button-icon="event-to"
        :minimum-view="options[1].input_type === 12 ? 'month': 'day'"
        :class="[
          !isLog && optionData(options[1].id).errMessages.length > 0 ? 'errItem' : '',
        ]"
      />
      <unicon name="calendar-alt"
        fill="#495057"
        class="app-parts-calendar-icon-to"
        ></unicon>
    </div>
    <div v-if="!isOld && !isLog">
      <div class="error my-1 ml-2"
        v-for="(msg, index) in optionData(options[0].id).errMessages" :key="`0_${index}`">
        {{msg}}
      </div>
      <div
        class="error my-1 ml-2"
        v-for="(msg, index) in optionData(options[1].id).errMessages" :key="`1_${index}`">
        {{msg}}
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import datepicker from 'vuejs-datepicker';
import { ja } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'PartsApplicationCalendar',
  components: {
    'v-calendar': datepicker,
  },
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    maxLength: {
      type: Object,
      default: () => ({
        year: 4,
        month: 2,
        day: 2,
      }),
    },
    size: { type: String, default: '' },
    isOld: { type: Boolean, default: false },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
  },
  data() {
    return {
      DatePickerFormat: 'yyyy/MM/dd',
      DatePickerFormat2: 'yyyy/MM',
      ja,
      timeObj: {
        ymd: '',
      },
      monthOptions: [],
      dayOptions: [],
    };
  },
  watch: {
    value() {
      this.setData(this.value);
    },
  },
  methods: {
    async changeData(keyName, eValue) {
      let value = eValue;
      // カレンダーの場合は余計な要素が多数ついているのでYmdだけ取得する
      if (keyName === 'ymd') {
        value = moment(eValue).format('YYYY/MM/DD');
      }
      // localの各値にセットする。
      this.$set(this.timeObj, keyName, value);
      const emptyKey = Object.keys(this.timeObj).find((key) => {
        return this.timeObj[key] === '';
      });

      // セットされていない値があれば日付データを作らない
      if (emptyKey) {
        return;
      }
      const data = this.timeObj;
      // すべての日付が入力されたら、日付として有効かどうかをチェックする。
      const strValue = `${data.ymd}`;
      if (!moment(strValue, 'YYYY/MM/DD').isValid()) {
        return;
      }

      const param = this.updateParam ? Object.assign(this.updateParam) : {};
      param.value = strValue;
      this.$emit('change', param);
    },
    init() {
      this.timeObj = {
        ymd: '',
      };
    },
    setData(value) {
      const t = moment(value, 'YYYY/MM/DD HH:mm').isValid() ? value : null;
      if (t === null) {
        this.init();
        return;
      }
      this.timeObj.ymd = moment(value).format('YYYY/MM/DD');
    },
    kickSetText(eValue, optionId, itemNo) {
      let value = null;
      if (moment(eValue).isValid()) {
        if (this.options[1].input_type === 12 && itemNo === 2) {
          value = moment(eValue).endOf('month').format('YYYY/MM/DD');
        } else {
          // momentの正式な時間の値をYMDに変換
          value = moment(eValue).format('YYYY/MM/DD');
        }
      }
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1,
        value,
      };
      this.$emit('kickSetText', param);
    },
  },
  computed: {
    inputs() {
      if (this.isLog) {
        return [];
      }
      if (!this.isOld) {
        return this.$store.state.gasApplicationInput.inputs;
      }
      return this.$store.state.gasApplicationOldInput.inputs;
    },
    optionData() {
      const self = this;
      return (optionId) => {
        const target = self.inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          const sameAnswerNo = input.answer_no === 1;
          return sameField && sameOption && sameAnswerNo;
        });
        return target;
      };
    },
  },
  created() {
    if (this.value) {
      const timeObj = {
        ymd: moment(this.value).format('YYYY/MM/DD'),
      };
      this.timeObj = timeObj;
    }
  },
};
</script>

<style>
  .app-parts-calendar-icon-from, .app-parts-calendar-icon-to {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    right: 1px;
    z-index: 1;
    top: 10px;
    left: 180px;
  }
.event-from, .event-to {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  right: 1px;
  z-index: 2;
}
  .app-parts-calendar {
    width: 220px;
  }
</style>
<style scoped>

  .error {
    font-size: 80%;
    color: #dc3545;
  }
</style>
