<template>
  <div>
    <component
      :is="`v-table-template-${view_template}`"
      :fieldId='fieldId'
      :is_variable="is_variable"
      :input_number="input_number"
      :min_input_number="min_input_number"
      :max_input_number="max_input_number"
      :options.sync="options"
      :notice="notice"
      :writable="writable"
      :view_template="view_template"
      :rules="rules"
      :isOld="isOld"
      :isLog="isLog"
      :logData="logData"
      :controlFormatter="controlFormatter"
      :controlInputType="controlInputType"
      @kickSetText="kickSetText($event)"
      @kickSetSelect="kickSetSelect($event)"
      @kickSetFile="kickSetFile($event)"
      />

    <b-row
      v-if="notice"
      class="ml-1">
      <b-col class="px-0">
        <p class="mb-0 f-13">{{notice}}</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Template1 from '@/components/parts/application/tables/template_1.vue';
import Template2 from '@/components/parts/application/tables/template_2.vue';

export default {
  name: 'PartsApplicationTable',
  components: {
    'v-table-template-1': Template1,
    'v-table-template-2': Template2,
  },
  props: {
    fieldId: { type: Number },
    notice: {},
    is_variable: { type: [Boolean, Number], default: true },
    input_number: { type: Number, default: 1 },
    min_input_number: { type: Number, default: 1 },
    max_input_number: { type: Number, default: 1 },
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            input_type: null,
            prefix: '',
            suffix: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    view_template: { type: Number, default: 1 },
    rules: { type: [Object, Array], fedault: () => {} },
    isOld: { type: Boolean, default: false },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
    controlFormatter: { type: Function },
    controlInputType: { type: Function },
  },
  data() {
    return {
    };
  },
  methods: {
    kickSetText(param) {
      this.$emit('kickSetText', param);
    },
    kickSetSelect(param) {
      this.$emit('kickSetSelect', param);
    },
  },
  computed: {
  },
};
</script>

<style scoped>
</style>
