<template>
  <div>
    <div
      class="my-2 ml-1">
      <b-row>
        <b-col class="px-0">
          <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle">
              <span
                :class="[
                  'optionLabel',
                  'mr-1',
                  `label_1`
                ]">国内</span>
            </b-input-group-prepend>
            <!-- テキストと数値 -->
            <b-form-input
              :type="controlInputType(options[0].rule_id_list)"
              :disabled="!writable"
              :class="[
                !isLog && optionData(options[0].id).errMessages.length > 0 ? 'errItem' : '',
                `template_${options[0].view_template}`
              ]"
              :value="!isLog ? optionData(options[0].id).field_answer_text
              : logData[`${fieldId}_${options[0].id}_1`].field_answer_text"
              :formatter="controlFormatter(options[0].rule_id_list)"
              @input="kickSetText($event, options[0].id)"
              :maxLength="options[0].max"
              :placeholder="options[0].placeholder"/>
              <b-input-group-append
                class="ml-2 flex flexMiddle">
                {{options[0].suffix}}
              </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col class="px-0">
          <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle">
              <span
                :class="[
                  'optionLabel',
                  'mr-1',
                  `label_1`
                ]">国外</span>
            </b-input-group-prepend>
            <!-- テキストと数値 -->
            <b-form-input
              :type="controlInputType(options[1].rule_id_list)"
              :disabled="!writable"
              :class="[
              !isLog && optionData(options[1].id).errMessages.length > 0 ? 'errItem' : '',
                `template_${options[1].view_template}`
              ]"
              :value="!isLog ? optionData(options[1].id).field_answer_text
              : logData[`${fieldId}_${options[1].id}_1`].field_answer_text"
              :formatter="controlFormatter(options[1].rule_id_list)"
              @input="kickSetText($event, options[1].id)"
              :maxLength="options[1].max"
              :placeholder="options[1].placeholder"/>
              <b-input-group-append
                class="ml-2 flex flexMiddle">
                {{options[1].suffix}}
              </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col class="px-0">
          <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle">
              <span
                :class="[
                  'optionLabel',
                  'mr-1',
                  `label_1`
                ]">合計人数</span>
            </b-input-group-prepend>
            <!-- テキストと数値 -->
            <b-form-input
              :value="sumValue"
              :disabled="true"
              :class="[
                `template_4`
              ]"
              :maxLength="5"
              placeholder=""/>
              <b-input-group-append
                class="ml-2 flex flexMiddle">
                人
              </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>

export default {
  name: 'PartsApplicationSpecialParticipant',
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    view_template: { type: Number, default: 1 },
    rules: { type: [Object, Array], fedault: () => {} },
    isOld: { type: Boolean, default: false },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
    controlFormatter: { typr: Function },
    controlInputType: { type: Function },
  },
  data() {
    return {
      local_data: {},
    };
  },
  methods: {
    kickSetText(value, optionId) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1,
        value,
      };
      this.$emit('kickSetText', param);
      this.$set(this.local_data, optionId, value);
    },
  },
  computed: {
    inputs() {
      if (!this.isOld) {
        return this.$store.state.gasApplicationInput.inputs;
      }
      return this.$store.state.gasApplicationOldInput.inputs;
    },
    optionData() {
      const self = this;
      return (optionId) => {
        const optionData = self.inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          return sameField && sameOption;
        });
        if (typeof optionData === 'undefined') {
          return [];
        }
        return optionData;
      };
    },
    sumValue() {
      let sum = 0;
      Object.keys(this.local_data).forEach((optionId) => {
        const value = Number(this.local_data[optionId]);
        if (Number.isFinite(value)) {
          sum += value;
        }
      });
      return sum;
    },
  },
  created() {
    const self = this;
    if (!this.isLog) {
      let targetInputs = [];
      if (!this.isOld) {
        targetInputs = this.$store.state.gasApplicationInput.inputs;
      } else {
        targetInputs = this.$store.state.gasApplicationOldInput.inputs;
      }
      const options = targetInputs.filter((input) => {
        const sameField = input.field_id === self.fieldId;
        return sameField;
      });
      options.forEach((option) => {
        this.$set(this.local_data, option.option_id, option.field_answer_text);
      });
    } else {
      this.$set(this.local_data, this.options[0].option_id, this.logData[`${this.fieldId}_${this.options[0].id}_1`].field_answer_text);
      this.$set(this.local_data, this.options[1].option_id, this.logData[`${this.fieldId}_${this.options[1].id}_1`].field_answer_text);
    }
  },
};
</script>

<style scoped>
.errItem {
  border:solid 1px #dc3545 !important;
}
.error {
  font-size: 80%;
  color: #dc3545;
}
input {
  height: 50px;
}
.template_1 {
  width: 220px;
  max-width: 220px;
}

.template_2 {
  width: 400px;
  max-width: 400px;
}

.optionLabel {
  width: 105px;
}

.errorWrap {
  margin-left: 110px;
}

/* 長 */
.template_1 {}

/* 中 */
.template_2 {
  width: 400px;
  max-width: 400px;
}

/* 短 */
.template_3 {
  width: 220px;
  max-width: 220px;
}

/* 極短 */
.template_4 {
  width: 120px;
  max-width: 120px;
}
</style>
