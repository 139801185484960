<template>
  <div>
    <div
      v-for="(option, index) in options" :key="index"
      class="my-2 ml-1">
      <b-row>
        <b-col class="" sm="12">

          <b-input-group>
            <!-- 何もないとき -->
            <span
              v-if="!isLog && !optionData(option.id).uploadedFileName && !optionData(option.id).dbFileName"
              class="mb-2 fileName">添付ファイル名：ファイルが選択されていません</span>
            <!-- ログでなく、すでにアップロードされているとき -->
            <span
              class="mb-2 fileName"
              v-else-if="!isLog && optionData(option.id).uploadedFileName">
              添付ファイル名：<b-link @click="downloadApploadedFile(optionData(option.id).uploadedFileKey, 0, optionData(option.id).uploadedFileName)">{{optionData(option.id).uploadedFileName}}</b-link>
              <img
                src="@/assets/svg/close.svg"
                v-if="!isLog && writable"
                @click="kickDeleteFile(option.id)"
                class="delete_file_btn">
            </span>
            <span
              class="mb-2 fileName"
              v-else-if="!isLog && !optionData(option.id).uploadedFileName ? optionData(option.id).dbFileName : logData[`${fieldId}_${option.id}_1`].file_path">
              添付ファイル名：
              <b-link
                @click="downloadFormFile(
                  fieldId, option.id,
                  !isLog ? optionData(option.id).dbFileName : logData[`${fieldId}_${option.id}_1`].file_path,
                  isOld,
                  isLog,
                  logData ? logData.sort : null)"
                class="mb-1 font-is-bold">{{!isLog ? optionData(option.id).dbFileName : logData[`${fieldId}_${option.id}_1`].file_path}}</b-link>
              <b-form-checkbox
                v-if="!isLog && writable"
                @change="toggleDoDeleteDbFile(optionData(option.id))"
                :checked="optionData(option.id).do_delete_file"
                class="ml-3 inlineBlock"
                switches>削除</b-form-checkbox>
            </span>
            <span v-else class="mb-2 fileName">添付ファイル名：ファイルが選択されていません</span>
          </b-input-group>

          <b-input-group v-if="writable && inNoneInput === false">
            <b-input-group-prepend
              class="mr-1 flex flexMiddle">
              {{option.label}}
              {{option.prefix}}
            </b-input-group-prepend>
            <div
              @dragover.prevent="toggleDrag($event, true)"
              @dragleave.prevent="toggleDrag($event, false)"
              @drop.prevent="onDrop($event, option.id)"
              :class="[
                isDragging ? 'is-dragging' : '',
                !isLog && optionData(option.id).errMessages.length > 0 ? 'errItem' : '',
                'drop', 'flex', 'flexMiddle', 'flexCenter'
              ]">
              <span>
                <span
                  :class="[isDragging ? 'is-dragging' : '',
                    'dragText',
                    'fw-6']">ここにファイルをドラッグ＆ドロップ または</span>
                <label
                  :for="`file_${fieldId}`"
                  class="fileText fw-6 selectFile">［ ファイルを選択 ］</label>
                <p
                  :class="[isDragging ? 'is-dragging' : '', 'fileNotation', 'mb-0']">
                  （ファイルを再アップロードした場合アップロード済みのファイルは上書きされます）
                  </p>
                <input type="file" style="display:none;"
                  :id="`file_${fieldId}`"
                  @change="upload($event, option.id)"
                >
              </span>
            </div>

            <b-input-group-append
              class="ml-1 flex flexMiddle">
              {{option.suffix}}
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
        v-if="!isOld && !isLog"
        class="ml-1">
        <p class="error my-1"
          v-for="(msg, index) in optionData(option.id).errMessages" :key="index">
          {{msg}}
        </p>
      </b-row>

    </div>
  </div>
</template>
<script>

export default {
  name: 'PartsApplicationFile',
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    downloadFormFile: { type: Function },
    isOld: { type: Boolean, default: false },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
    inNoneInput: { type: Boolean, default: false },
    downloadApploadedFile: { type: Function },
  },
  data() {
    return {
      doDeleteUploadedFile: false,
      isDragging: false,
    };
  },
  methods: {
    upload(event, optionId) {
      if (event.target.files.length === 0) {
        return;
      }
      const file = event.target.files[0];
      this.kickSetFile(file, optionId);
    },
    kickSetFile(value, optionId) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
        file: value,
      };
      this.$emit('kickSetFile', param);
    },
    kickDeleteFile(optionId) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
      };
      this.$emit('kickDeleteFile', param);
      document.getElementById(`file_${this.fieldId}`).value = '';
    },
    toggleDoDeleteDbFile(input) {
      this.$store.commit('gasApplicationInput/toggleDoDeleteDbFile', input);
    },
    toggleDrag(event, isDragging) {
      this.isDragging = isDragging;
    },
    onDrop(event, optionId) {
      this.isDragging = false;
      const fileList = event.target.files ? event.target.files : event.dataTransfer.files;
      // ファイルが無い時処理を中止
      if (fileList.length === 0) {
        return false;
      }
      const file = fileList[0];
      this.kickSetFile(file, optionId);
      return true;
    },
  },
  computed: {
    inputs() {
      if (this.isLog) {
        return [];
      }
      if (!this.isOld) {
        return this.$store.state.gasApplicationInput.inputs;
      }
      return this.$store.state.gasApplicationOldInput.inputs;
    },
    optionData() {
      const self = this;
      return (optionId) => {
        const target = self.inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          const sameAnswerNo = input.answer_no === 1;
          return sameField && sameOption && sameAnswerNo;
        });
        return target;
      };
    },
    isCms() {
      return this.$store.state.gasApplicationForm.isCms;
    },
  },
};
</script>

<style scoped>
  .font-is-bold {
    font-weight: bold;
  }
  .error {
    font-size: 80%;
    color: #dc3545;
  }

  .drop {
    height: 120px;
    width: 100%;
    background-color: #F3F3F3;
    border-color: #CCC;
    border-style: dashed;
    border-radius: 10px;
    border-width: 1px;
  }

  .dragText {
    color: #BBB;
  }

  .fileText {
    color: #0A8EA7;
  }

  .fileNotation {
    color: #BBB;
  }

  .is-dragging.drop {
    background-color: #E6E6E6;
    border-color: #6E6E6E;
  }

  .is-dragging {
    color: #6E6E6E;
  }

  .selectFile {
    cursor: pointer;
  }
  .selectFile:hover {
    text-decoration: underline;
  }

  .fileName {
    margin-top: 16px;
  }

  .errItem {
    border:solid 1px #dc3545 !important;
  }

  .delete_file_btn {
    width: 20px;
  }
  .delete_file_btn:hover {
    cursor: pointer;
  }
</style>
