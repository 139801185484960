var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{staticClass:"mt-2 mb-0 adjust-left",attrs:{"borderless":true}},[_c('b-thead',[_c('b-tr',[_vm._l((_vm.options),function(option,index){return _c('b-th',{key:index,staticClass:"adjust-th"},[_c('span',{staticClass:"fw-3 ml-2"},[_vm._v(_vm._s(option.label))])])}),_c('b-th',{staticClass:"pb-0 pr-0 deleteTh"},[_c('span',{staticClass:"fw-3"},[_vm._v("削除")])])],2)],1),_c('b-tbody',[_vm._l((_vm.maxAnswerNums[_vm.fieldId]),function(line){return _c('b-tr',{key:line,staticClass:"row-top"},[_vm._l((_vm.options),function(option,index){return _c('b-td',{key:index,staticClass:"p-0"},[(option.input_type===_vm.tableInputTypes.TEXT ||
              option.input_type===_vm.tableInputTypes.NUMBER)?_c('b-form-input',{class:[
              !_vm.isLog && _vm.optionData(option.id, line).errMessages.length > 0 ? 'errItem' : '',
              `template_${option.view_template}`
            ],attrs:{"type":_vm.controlInputType(option.rule_id_list),"disabled":!_vm.writable,"maxLength":option.max,"value":!_vm.isLog ? _vm.optionData(option.id, line).field_answer_text
            : _vm.logData[`${_vm.fieldId}_${option.id}_${line}`].field_answer_text,"formatter":_vm.controlFormatter(option.rule_id_list),"placeholder":option.placeholder},on:{"input":function($event){return _vm.kickSetText($event, option.id, line)}}}):_vm._e(),(option.input_type===_vm.tableInputTypes.SELECT)?_c('b-form-select',{class:!_vm.isLog && _vm.optionData(option.id, line).errMessages.length > 0 ? 'errItem' : '',attrs:{"disabled":!_vm.writable,"value":!_vm.isLog ? _vm.optionData(option.id, line).field_answer_id
            : _vm.logData[`${_vm.fieldId}_${option.id}_${line}`].field_answer_id,"options":option.choice_texts},on:{"input":function($event){return _vm.kickSetSelect($event, option.id, line)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v("-- 選択してください。 --")])]},proxy:true}],null,true)},[_vm._v(" > ")]):_vm._e(),(option.input_type===_vm.tableInputTypes.FIXED)?_c('span',[_vm._v(" "+_vm._s(_vm.options[_vm.n].choice_texts)+" ")]):_vm._e(),(option.input_type===_vm.tableInputTypes.DATETIME)?_c('span',[_vm._v(" datetime ")]):_vm._e(),(!_vm.isLog && !_vm.isOld)?_c('b-row',{staticClass:"ml-1"},_vm._l((_vm.optionData(option.id, line).errMessages),function(msg,index){return _c('p',{key:index,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_vm._e()],1)}),_c('b-td',{staticClass:"closeTh flex flexMiddle filexCenter"},[(_vm.writable)?_c('img',{staticClass:"tdDeleteBtn",attrs:{"src":require("@/assets/svg/close.svg")},on:{"click":function($event){return _vm.removeTr(line)}}}):_vm._e()])],2)}),(_vm.sum_column)?_c('b-tr',_vm._l((_vm.options),function(option,index){return _c('b-td',{key:index},[(option.id == _vm.rules.sum_column.option_id)?_c('div',[_c('span',{staticClass:"sum"},[_vm._v("合計：")]),_c('b-form-input',{class:[
                !_vm.sumMatch ? 'errItem' : '',
              ],attrs:{"readonly":"","value":_vm.sum}}),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sumMatch),expression:"!sumMatch"}],staticClass:"ml-1"},[_c('p',{staticClass:"error my-1"},[_vm._v(" "+_vm._s(_vm.sumMsg)+" ")])])],1):_vm._e()])}),1):_vm._e()],2)],1),(_vm.writable)?_c('div',{staticClass:"flex adjust-left mt-2"},[_c('b-button',{staticClass:"addBtn",attrs:{"disabled":!_vm.writable || !(this.maxAnswerNums[this.fieldId] < this.max_input_number),"variant":"outline-primary"},on:{"click":_vm.addTr}},[_c('img',{staticClass:"plus",attrs:{"src":require("@/assets/svg/plus.svg")}}),_vm._v(" 入力欄を追加 ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }