<template>
  <div>
    <div
      class="my-2 ml-1"
      v-for="(option, index) in options" :key="index">
      <b-row>
        <b-col class="px-0">
          <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle">
              <span
                v-if="option.label"
                :class="[
                  'optionLabel',
                  'mr-1',
                  `label_${view_template}`
                ]">{{option.label}}</span>
              {{option.prefix}}
            </b-input-group-prepend>
            <!-- テキストと数値 -->
            <b-form-input
              :type="controlInputType(option.rule_id_list)"
              v-if="option.input_type === 1 || option.input_type === 4"
              :disabled="!writable"
              :class="[
                !isLog && optionData(option.id).errMessages.length > 0 ||
                  (ltTarget(option.id) && !lt)
                  ? 'errItem' : '',
                `template_${option.view_template}`
              ]"
              :maxLength="option.max"
              inputMode="verbatim"
              :value="!isLog ? optionData(option.id).field_answer_text
                : logData[`${fieldId}_${option.id}_1`].field_answer_text"
              :formatter="controlFormatter(option.rule_id_list)"
              @input="kickSetText($event, option.id)"
              :placeholder="option.placeholder"/>
            <!-- セレクトボックス -->
            <b-form-select
              :disabled="!writable"
              :class="[
                !isLog && optionData(option.id).errMessages.length > 0 ||
                  (ltTarget(option.id) && !lt)
                  ? 'errItem' : '',
                `template_${option.view_template}`
              ]"
              v-if="option.input_type === 2"
              :value="!isLog ? optionData(option.id).field_answer_id
              : logData[`${fieldId}_${option.id}_1`].field_answer_id"
              @input="kickSetSelect($event, option.id)"
              :options="option.choice_texts"
              >
                <template v-slot:first>
                  <option :value="null">-- 選択してください。 --</option>
                </template>
              </b-form-select>
            <b-input-group-append
              class="ml-2 flex flexMiddle">
              {{option.suffix}}
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
        v-if="!isOld && !isLog"
        :class="[option.label ? `error_${view_template}` : 'ml-1']">
        <p class="error my-1"
          v-for="(msg, index) in optionData(option.id).errMessages" :key="index">
          {{msg}}
        </p>
        <p class="error my-1"
          v-show="(ltTarget(option.id) && !lt)">{{ltMsg}}</p>
      </b-row>
    </div>
  </div>
</template>
<script>
import { Core as YubinBangoCore } from 'yubinbango-core';

export default {
  name: 'PartsApplicationTextsTemplate1',
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    view_template: { type: Number, default: 1 },
    rules: { type: [Object, Array], fedault: () => {} },
    isOld: { type: Boolean, default: false },
    params: { type: [Object, Array], default: () => [] },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
    controlFormatter: { typr: Function },
    controlInputType: { type: Function },
  },
  data() {
    return {
    };
  },
  methods: {
    kickSetText(value, optionId) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1, // テキストは当面１つ固定
        value,
      };
      this.$emit('kickSetText', param);
    },
    kickSetSelect(value, optionId) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1, // テキストは当面１つ固定
        value,
      };
      this.$emit('kickSetSelect', param);
    },
  },
  computed: {
    isOnlyHalfNumber() {
      return (ruleIdList) => {
        return ruleIdList.includes(17);
      };
    },
    inputs() {
      if (this.isLog) {
        return [];
      }
      if (!this.isOld) {
        return this.$store.state.gasApplicationInput.inputs;
      }
      return this.$store.state.gasApplicationOldInput.inputs;
    },
    optionData() {
      const self = this;
      return (optionId) => {
        const optionData = self.inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          const sameAnswerNo = input.answer_no === 1;
          return sameField && sameOption && sameAnswerNo;
        });
        return optionData;
      };
    },
    lt_column() {
      return this.rules.lt_column;
    },
    sum() {
      let sum = '';
      if (this.rules.lt_column) {
        const optionId = this.rules.lt_column.option_id;
        sum = 0;
        this.inputs.forEach((input) => {
          const sameOption = input.option_id === optionId;
          if (sameOption) {
            sum += Number(input.field_answer_text);
          }
        });
      }
      return sum;
    },
    lt() {
      let result = true;
      let targetValue = '';
      if (this.rules.lt_column) {
        const optionId = Number(this.lt_column.params[1].parameter);
        const targetOption = this.inputs.find((input) => {
          const sameOption = input.option_id === optionId;
          const sameAnswer = input.answer_no === 1;
          return sameOption && sameAnswer;
        });
        targetValue = Number(targetOption.field_answer_text);
        result = targetValue >= this.sum;
      }
      return result;
    },
    ltTarget() {
      return (optionId) => {
        if (typeof this.lt_column === 'undefined') {
          return false;
        }
        return this.lt_column.option_id === optionId;
      };
    },
    ltMsg() {
      let msg = '';
      if (this.rules.lt_column) {
        const data = this.$store.state.gasApplicationForm.optionDataList;
        // const { fieldLabel, optionLabel } = data[this.lt_column.params[1].parameter];
        const { optionLabel } = data[this.lt_column.params[1].parameter];
        msg = `${optionLabel}以下の値を入力してください。`;
      }
      return msg;
    },
    // 住所のとき、郵便番号の監視
    postalCode() {
      // 0は識別番号。1が郵便番号を示す。1は郵便番号のフィールドID、2は郵便番号のオプションID
      if ((!this.params[0] || this.params[0].param !== 1) || !this.params[1] || !this.params[2]) {
        return null;
      }
      const postalFieldId = this.params[1].param;
      const postalOptionId = this.params[2].param;
      const postalData = this.inputs.find((input) => {
        const sameField = input.field_id === postalFieldId;
        const sameOption = input.option_id === postalOptionId;
        const sameAnswerNo = input.answer_no === 1;
        return sameField && sameOption && sameAnswerNo;
      });
      return postalData.field_answer_text;
    },
  },
  watch: {
    postalCode(newPostalCode) {
      if (newPostalCode.length < 7) {
        return;
      }
      let address = '';
      let optionId = this.options[0].id;
      if (this.params[3]) {
        optionId = this.params[3].param;
      }
      // eslint-disable-next-line
      new YubinBangoCore(newPostalCode, (addr) => {
        address = addr.region + addr.locality + addr.street;
        if (address === '') {
          return;
        }
        this.kickSetText(address, optionId);
      });
    },
  },
};
</script>

<style scoped>
.errItem {
  border:solid 1px #dc3545 !important;
}
.error {
  font-size: 80%;
  color: #dc3545;
}
input, select {
  height: 50px;
}

/* 長 */
.template_1 {}

/* 中 */
.template_2 {
  width: 400px;
  max-width: 400px;
}

/* 短 */
.template_3 {
  width: 220px;
  max-width: 220px;
}

/* 極短 */
.template_4 {
  width: 120px;
  max-width: 120px;
}

.error_1 {
  margin-left: 125px;
}

.error_2 {
  margin-left: 165px;
}
</style>
