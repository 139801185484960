<template>
  <div class="mt-10px collaborator-wrap">

    <div
      class="my-2 ml-4">
      <div v-for="(line) of maxAnswerNums " :key="line"
        class="groupInputs mt-2 mb-3">
        <div class="mt-10px" v-if="writable">
          <span class="deleteText mt-10px">削除</span>
        </div>
        <div class="deleteBtn">
          <img
            src="@/assets/svg/close.svg"
            v-if="writable"
            @click="removeTr(line)"
            class="tdDeleteBtn">
        </div>

        <!-- 一列目 -->
        <div
          class="badge badge-optional number-badge">
          {{line}}人目
        </div>
        <div>
          <div
            class="inlineBlock mr-20px mt-10px">
            <span class="ml-1 mb-1">{{options[0].label}}</span>
            <b-form-input
              :disabled="!writable"
              :class="[
                !isLog && optionData(options[0].id, line).errMessages && optionData(options[0].id, line).errMessages.length > 0 ? 'errItem' : '',
                'inputTemplate_' + options[0].view_template,
              ]"
              :maxLength="options[0].max"
              :value="!isLog ? optionData(options[0].id, line).field_answer_text
              : logData[`${fieldId}_${options[0].id}_${line}`].field_answer_text"
              @input="kickSetText($event, options[0].id, line)"
              v-if="options[0].input_type===tableInputTypes.TEXT ||
                options[0].input_type===tableInputTypes.NUMBER"
              :placeholder="options[0].placeholder"/>
            <b-row
              v-if="!isLog && !isOld"
              class="ml-1">
              <p
                v-for="(msg, index) in optionData(options[0].id, line).errMessages" :key="index"
                class="error my-1">
                {{msg}}
              </p>
            </b-row>
          </div>

          <div
            class="inlineBlock mr-20px mt-10px">
            <span class="ml-1 mb-1">{{options[1].label}}</span>
            <b-form-input
              :disabled="!writable"
              :class="[
                !isLog && optionData(options[1].id, line).errMessages && optionData(options[1].id, line).errMessages.length > 0 ? 'errItem' : '',
                'inputTemplate_' + options[1].view_template,
              ]"
              :maxLength="options[1].max"
              :value="!isLog ? optionData(options[1].id, line).field_answer_text
              : logData[`${fieldId}_${options[1].id}_${line}`].field_answer_text"
              @input="kickSetText($event, options[1].id, line)"
              v-if="options[1].input_type===tableInputTypes.TEXT ||
                options[1].input_type===tableInputTypes.NUMBER"
              :placeholder="options[1].placeholder"/>
            <b-row
              v-if="!isLog && !isOld"
              class="ml-1">
              <p
                v-for="(msg, index) in optionData(options[1].id, line).errMessages" :key="index"
                class="error my-1">
                {{msg}}
              </p>
            </b-row>
          </div>
        </div>

        <!-- 二列目 -->
        <div class="mt-2">
          <p class="ml-1 mb-1">{{options[2].label}}/{{options[3].label}}</p>
          <div class="ml--15px">
            <b-col class="inlineBlock yearWrap year">
              <b-form-input
                :type="controlInputType(options[2].rule_id_list)"
                maxLength="4"
                :disabled="!writable"
                :value="dates[line-1] ? dates[line-1].year : ''"
                :formatter="controlFormatter(options[2].rule_id_list)"
                @input="inputDate($event, 'year', line)"
                :placeholder="nonePlace ? '':'例）1970'"/>
            </b-col>
            <span>年</span>
            <b-col class="inlineBlock monthWrap">
              <b-form-select
                :disabled="!writable"
                class="selectDate"
                :options="monthOption"
                :value="dates[line-1] ? dates[line-1].month : ''"
                @input="inputDate($event, 'month', line)"
              ></b-form-select>
            </b-col>
            <span>月</span>
            <b-col class="inlineBlock dayWrap">
              <b-form-select
                :disabled="!writable"
                class="selectDate"
                :options="dayOption"
                :value="dates[line-1] ? dates[line-1].day : ''"
                @input="inputDate($event, 'day', line)"
              ></b-form-select>
            </b-col>
            <span>日</span>
            <b-col class="inlineBlock ageWrap age ml-2">
              <b-form-input
                :value="!isLog ? optionData(options[3].id, line).field_answer_text
                : logData[`${fieldId}_${options[3].id}_${line}`].field_answer_text"
                @input="kickSetText($event, options[3].id, line)"
                :disabled="true"
                :placeholder="nonePlace ? '':'例）30'"/>
            </b-col>
            <span>歳</span>
          </div>
          <b-row
            v-if="!isLog && !isOld"
            class="ml-1">
            <p
              v-for="(msg, index) in optionData(options[2].id, line).errMessages" :key="index"
              class="error my-1">
              {{msg}}
            </p>
            <p
              v-for="(msg, index) in optionData(options[3].id, line).errMessages" :key="index"
              class="error my-1">
              {{msg}}
            </p>
          </b-row>
        </div>

        <!-- 3列目 -->
        <div class="mt-2">
          <p class="ml-1 mb-1">{{options[4].label}}</p>
          <div>
            <b-form-textarea
              :class="[
                !isLog && optionData(options[4].id, line).errMessages && optionData(options[4].id, line).errMessages.length > 0 ? 'errItem' : '',
                'inputTemplate_' + options[4].view_template,
                'coworker-textarea'
              ]"
              :disabled="!writable"
              :value="!isLog ? optionData(options[4].id, line).field_answer_text
                : logData[`${fieldId}_${options[4].id}_${line}`].field_answer_text"
              @input="kickSetText($event, options[4].id, line)"
              :rows="4"
              :maxLength="options[4].max"
              />
            <b-row
              v-if="!isLog && !isOld"
              class="ml-1">
              <p
                v-for="(msg, index) in optionData(options[4].id, line).errMessages" :key="index"
                class="error my-1">
                {{msg}}
              </p>
            </b-row>
          </div>
        </div>

        <!-- 4列目 -->
        <div class="mt-2">
          <p class="ml-1 mb-1">{{options[5].label}}</p>
          <div>
            <b-form-input
              :disabled="!writable"
              :class="[
                !isLog && optionData(options[5].id, line).errMessages && optionData(options[5].id, line).errMessages.length > 0 ? 'errItem' : '',
                'inputTemplate_' + options[5].view_template,
              ]"
              :maxLength="options[5].max"
              :value="!isLog ? optionData(options[5].id, line).field_answer_text
              : logData[`${fieldId}_${options[5].id}_${line}`].field_answer_text"
              @input="kickSetText($event, options[5].id, line)"
              v-if="options[5].input_type===tableInputTypes.TEXT ||
                options[5].input_type===tableInputTypes.NUMBER"
              :placeholder="options[5].placeholder"/>
            <b-row
              v-if="!isLog && !isOld"
              class="ml-1">
              <p
                v-for="(msg, index) in optionData(options[5].id, line).errMessages" :key="index"
                class="error my-1">
                {{msg}}
              </p>
            </b-row>
          </div>
        </div>

        <!-- 5列目 -->
        <div class="mt-2">
          <p class="ml-1 mb-1">{{options[6].label}}</p>
          <div>
            <b-form-textarea
              :class="[
                !isLog && optionData(options[6].id, line).errMessages && optionData(options[6].id, line).errMessages.length > 0 ? 'errItem' : '',
                'inputTemplate_' + options[6].view_template,
                'coworker-textarea'
              ]"
              :disabled="!writable"
              :value="!isLog ? optionData(options[6].id, line).field_answer_text
                : logData[`${fieldId}_${options[6].id}_${line}`].field_answer_text"
              @input="kickSetText($event, options[6].id, line)"
              :rows="4"
              :maxLength="options[6].max"
              />
            <b-row
              v-if="!isLog && !isOld"
              class="ml-1">
              <p
                v-for="(msg, index) in optionData(options[6].id, line).errMessages" :key="index"
                class="error my-1">
                {{msg}}
              </p>
            </b-row>
          </div>
        </div>

        <!-- 6列目 -->
        <div class="mt-2">
          <p class="ml-1 mb-1">{{options[7].label}}</p>
          <div>
            <b-form-textarea
              :class="[
                !isLog && optionData(options[7].id, line).errMessages && optionData(options[7].id, line).errMessages.length > 0 ? 'errItem' : '',
                'inputTemplate_' + options[7].view_template,
                'coworker-textarea'
              ]"
              :disabled="!writable"
              :value="!isLog ? optionData(options[7].id, line).field_answer_text
                : logData[`${fieldId}_${options[7].id}_${line}`].field_answer_text"
              @input="kickSetText($event, options[7].id, line)"
              :rows="4"
              :maxLength="options[7].max"
              />
            <b-row
              v-if="!isLog && !isOld"
              class="ml-1">
              <p
                v-for="(msg, index) in optionData(options[7].id, line).errMessages" :key="index"
                class="error my-1">
                {{msg}}
              </p>
            </b-row>
          </div>
        </div>
        <!-- 7列目 -->
        <div class="mt-2">
          <p class="ml-1 mb-1">{{options[8].label}}<span v-if="options[8].label === '永住許可証明書'" class="f-13 ml-2 bottom-msg-view7 ">＊該当する場合は必ずご提出ください。</span></p>
          <div>
            <b-row class="my-2 ml-1">
              <b-col class="pl-0" sm="12">
                <b-input-group>
                  <!-- 何もないとき -->
                  <span
                    v-if="!isLog && !optionData(options[8].id, line).uploadedFileName && !optionData(options[8].id, line).dbFileName"
                    class="mb-2 fileName">添付ファイル名：ファイルが選択されていません</span>
                  <!-- ログでなく、すでにアップロードされているとき -->
                  <span
                    class="mb-2 fileName test1"
                    v-else-if="!isLog && optionData(options[8].id, line).uploadedFileName">
                    添付ファイル名：<b-link @click="downloadApploadedFile(optionData(options[8].id, line).uploadedFileKey, 0, optionData(options[8].id, line).uploadedFileName)">{{optionData(options[8].id, line).uploadedFileName}}</b-link>
                    <img
                      src="@/assets/svg/close.svg"
                      v-if="!isLog && writable"
                      @click="kickDeleteFile(options[8].id, line)"
                      class="delete_file_btn">
                  </span>
                  <span
                    class="mb-2 fileName test2"
                    v-else-if="!isLog && !optionData(options[8].id, line).uploadedFileName ? optionData(options[8].id, line).dbFileName : logData[`${fieldId}_${options[8].id}_${line}`] ? logData[`${fieldId}_${options[8].id}_${line}`].file_path : ''">
                    添付ファイル名：
                    <b-link
                      @click="downloadUploadFile(
                        fieldId, options[8].id,
                        !isLog ? optionData(options[8].id, line).dbFileName : logData[`${fieldId}_${options[8].id}_${line}`] ? logData[`${fieldId}_${options[8].id}_${line}`].file_path:'',
                        isOld,
                        isLog,
                        logData ? logData.sort : null,
                        line)"
                      class="mb-1 font-is-bold">{{!isLog ? optionData(options[8].id, line).dbFileName :logData[`${fieldId}_${options[8].id}_${line}`] ? logData[`${fieldId}_${options[8].id}_${line}`].file_path:''}}</b-link>
                    <b-form-checkbox
                      v-if="!isLog && writable"
                      @change="toggleDoDeleteDbFile(optionData(options[8].id, line))"
                      :checked="optionData(options[8].id, line).do_delete_file"
                      class="ml-3 inlineBlock"
                      switches>削除</b-form-checkbox>
                  </span>
                  <span v-else class="mb-2 fileName">添付ファイル名：ファイルが選択されていません</span>
                </b-input-group>

                <b-input-group v-if="writable && inNoneInput === false">
                  <div
                    @dragover.prevent="toggleDrag($event, true)"
                    @dragleave.prevent="toggleDrag($event, false)"
                    @drop.prevent="onDrop($event, options[8].id, line)"
                    :class="[
                      isDragging ? 'is-dragging' : '',
                      !isLog && optionData(options[8].id, line).errMessages.length > 0 ? 'errItem' : '',
                      'drop', 'flex', 'flexMiddle', 'flexCenter'
                    ]">
                    <span>
                      <span
                        :class="[isDragging ? 'is-dragging' : '',
                          'dragText',
                          'fw-6']">ここにファイルをドラッグ＆ドロップ または</span>
                      <label
                        :for="`file_${fieldId}_${options[8].id}_${line}`"
                        class="fileText fw-6 selectFile">［ ファイルを選択 ］</label>
                      <p
                        :class="[isDragging ? 'is-dragging' : '', 'fileNotation', 'mb-0']">
                        （ファイルを再アップロードした場合アップロード済みのファイルは上書きされます）
                        </p>
                      <input type="file" style="display:none;"
                        :id="`file_${fieldId}_${options[8].id}_${line}`"
                        @change="upload($event, options[8].id, line)"
                      >
                    </span>
                  </div>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
              v-if="!isLog && !isOld"
              class="ml-1">
              <p
                v-for="(msg, index) in optionData(options[8].id, line).errMessages" :key="index"
                class="error my-1">
                {{msg}}
              </p>
            </b-row>
            <div class="ml-1">
              <p class="mb-0"><span class="f-13">＊書式は本ページトップよりダウンロードしてください。</span></p>
              <p class="mb-0"><span class="f-13">＊添付する際にはPDFに変換してください。</span></p>
              <p class="mb-0"><span class="f-13 bottom-msg-view2">＊30MB以下のPDFファイル</span></p>
              <p class="mb-0"><span class="f-13 bottom-msg-view2">＊A4縦でアップロードされていることをご確認ください。</span></p>
            </div>
          </div>
        </div>
        <!-- 7列目end -->
      </div>
    </div>

    <div class="flex"
      v-if="writable">
      <b-button
      class="addBtn"
      :disabled="!(this.maxAnswerNums < this.max_input_number)"
      @click="addTr"
      variant="outline-primary">
        <img class="plus" src="@/assets/svg/plus.svg">
        入力欄を追加
      </b-button>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import CONST_OPTIONS from '@/constants/options';
import TimeOptionHelper from '@/modules/TimeSelectBoxOption';

export default {
  name: 'PartsApplicationSpecialCollaborator',
  props: {
    fieldId: { type: Number },
    is_variable: { type: [Boolean, Number], default: true },
    input_number: { type: Number, default: 1 },
    min_input_number: { type: Number, default: 1 },
    max_input_number: { type: Number, default: 1 },
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            input_type: null,
            prefix: '',
            suffix: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
          },
        ];
      },
    },
    notice: {},
    writable: { type: Boolean, default: false },
    isOld: { type: Boolean, default: false },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
    downloadFormFile: { type: Function },
    controlFormatter: { typr: Function },
    controlInputType: { type: Function },
    nonePlace: { type: Boolean, default: false },
    isChangePlan: { type: Boolean, default: false },
    inNoneInput: { type: Boolean, default: false },
    isFirstLog: { type: Boolean, default: false },
    downloadApploadedFile: { type: Function },
  },
  data() {
    return {
      // 加工前日付データの一時保存先
      dates: [],
      oldLineList: [],
      doDeleteUploadedFile: false,
      isDragging: false,
    };
  },
  methods: {
    downloadUploadFile(fieldId, optionId, fileName, isOld, isLog, sort, answerNo) {
      this.$store.commit('gasApplicationInput/setAnsNum', answerNo);
      this.downloadFormFile(fieldId, optionId, fileName, isOld, isLog, sort);
    },
    addTr() {
      if (this.maxAnswerNums < this.max_input_number) {
        // データ上で入力項目の数を追加し、入力欄を追加する
        const newLineNum = this.maxAnswerNums + 1;
        const addParam = this._getInputParameter(newLineNum);
        const ansNumParam = {
          fieldId: this.fieldId,
          newLineNum,
        };
        this.dates.push({
          year: '',
          month: '',
          day: '',
        });
        this.$store.commit('gasApplicationInput/setMaxAnswerNums', ansNumParam);
        this.$store.commit('gasApplicationInput/addInputs', addParam);
      }
    },
    removeTr(line) {
      if (this.maxAnswerNums > this.min_input_number) {
        // 末尾の入力欄を削除し、入力項目の数も１つ減らす
        const removeParam = {
          fieldId: this.fieldId,
          line,
        };
        const newLineNum = this.maxAnswerNums - 1;
        const ansNumParam = {
          fieldId: this.fieldId,
          newLineNum,
        };

        this.dates.splice(line - 1, 1);
        this.$store.commit('gasApplicationInput/removeInputs', removeParam);
        this.$store.commit('gasApplicationInput/setMaxAnswerNums', ansNumParam);
      }
    },
    _getInputParameter(newLineNum) {
      const params = [];
      const commonParam = {
        field_id: this.fieldId,
        answer_no: newLineNum,
      };
      this.options.forEach((option) => {
        const param = Object.assign({}, commonParam);
        param.option_id = option.id;
        params.push(param);
      });
      return params;
    },
    kickSetText(value, optionId, lineNum) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: lineNum,
        value,
      };
      this.$emit('kickSetText', param);
    },
    kickSetSelect(value, optionId, lineNum) {
      let setValue = value;
      if (setValue) {
        setValue = Number(setValue);
      }
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: lineNum,
        value: setValue,
      };
      this.$emit('kickSetSelect', param);
    },
    kickDeleteFile(optionId, lineNum) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: lineNum,
      };
      this.$emit('kickDeleteFile', param);
      document.getElementById(`file_${this.fieldId}_${optionId}_${lineNum}`).value = '';
    },
    upload(event, optionId, lineNum) {
      if (event.target.files.length === 0) {
        return;
      }
      const file = event.target.files[0];
      this.kickSetFile(file, optionId, lineNum);
    },
    kickSetFile(value, optionId, lineNum) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: lineNum,
        file: value,
      };
      this.$emit('kickSetFile', param);
    },
    inputDate(value, key, line) {
      const index = line - 1;
      this.$set(this.dates[index], key, value);
      // いずれかが入力がなければ、空文字を入れる.
      if (!this.dates[index].year || !this.dates[index].month || !this.dates[index].day) {
        this.kickSetText('', this.options[2].id, line);
        this.kickSetText('', this.options[3].id, line);
      }
      // すべてに入力があれば日付データと年齢を入れる。
      if (this.dates[index].year && this.dates[index].month && this.dates[index].day) {
        const dateValue = `${this.dates[index].year}-${this.dates[index].month}-${this.dates[index].day}`;
        this.kickSetText(dateValue, this.options[2].id, line);
        if (this.dates[index].year.length === 4 && moment(new Date(dateValue)).isValid()) {
          const age = this.birth2age(dateValue);
          this.kickSetText(age, this.options[3].id, line);
        }
      }
    },
    birth2age(dateValue) {
      const birth = moment(new Date(dateValue));
      return moment().diff(birth, 'years');
    },
    // ここがおかしい！！
    setInintOldData() {
      const oldLine = this.$store.state.gasApplicationOldInput.maxAnswerNums[this.fieldId];
      for (let i = 0; i < oldLine; i += 1) {
        this.oldLineList.push(i + 1);
      }
      this.oldLineList.forEach((line) => {
        if (line > 1) {
          this.addTr();
        }
        this.options.forEach((option) => {
          const param = {
            field_id: this.fieldId,
            option_id: option.id,
            answer_no: line,
          };
          const oldData = this.$store.state.gasApplicationOldInput.inputs;
          const oldTargetIndex = this.searchTargetIndex(oldData, param);
          if (typeof oldTargetIndex !== 'undefined') {
            const setParam = {
              field_id: this.fieldId,
              option_id: option.id,
              answer_no: line,
              old_data: oldData[oldTargetIndex],
            };
            this.$store.commit('gasApplicationInput/oldCopy', setParam);
          }
        });
      });
      const ansNumParam = {
        fieldId: this.fieldId,
        newLineNum: this.$store.state.gasApplicationOldInput.maxAnswerNums[this.fieldId],
      };
      this.$store.commit('gasApplicationInput/setMaxAnswerNums', ansNumParam);
    },
    searchTargetIndex(array, param) {
      let foundIndex;
      array.find((input, index) => {
        const sameField = input.field_id === param.field_id;
        const sameOption = input.option_id === param.option_id;
        const sameAnswerNo = input.answer_no === param.answer_no;
        if (sameField && sameOption && sameAnswerNo) {
          foundIndex = index;
        }
        return sameField && sameOption && sameAnswerNo;
      });
      return foundIndex;
    },
    isEditChange() {
      const inputData = this.$store.state.gasApplicationInput.inputs;
      let editFlg = false;
      inputData.forEach((data) => {
        if (data.field_id === this.fieldId) {
          if (data.field_answer_text !== '') {
            editFlg = true;
          }
        }
      });
      return editFlg;
    },
    toggleDoDeleteDbFile(input) {
      this.$store.commit('gasApplicationInput/toggleDoDeleteDbFile', input);
    },
    toggleDrag(event, isDragging) {
      this.isDragging = isDragging;
    },
    onDrop(event, optionId, line) {
      this.isDragging = false;
      const fileList = event.target.files ? event.target.files : event.dataTransfer.files;
      // ファイルが無い時処理を中止
      if (fileList.length === 0) {
        return false;
      }
      const file = fileList[0];
      this.kickSetFile(file, optionId, line);
      return true;
    },
  },
  computed: {
    maxAnswerNums() {
      if (!this.isOld && !this.isLog) {
        return this.$store.state.gasApplicationInput.maxAnswerNums[this.fieldId];
      }
      if (this.isFirstLog) {
        const maxAnswers = [];
        Object.keys(this.logData).forEach((key) => {
          const keys = key.split('_');
          if (keys[2] && !maxAnswers.includes(keys[2])) {
            maxAnswers.push(keys[2]);
          }
        });
        return maxAnswers.length;
      }
      return this.$store.state.gasApplicationOldInput.maxAnswerNums[this.fieldId];
    },
    tableInputTypes() {
      return CONST_OPTIONS.TABLE_INPUT_TYEPS;
    },
    inputs() {
      if (this.isLog) {
        return [];
      }
      if (!this.isOld) {
        return this.$store.state.gasApplicationInput.inputs;
      }
      return this.$store.state.gasApplicationOldInput.inputs;
    },
    optionData() {
      const self = this;
      return (optionId, line) => {
        const optionData = self.inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          const sameAnswerNo = input.answer_no === line;
          return sameField && sameOption && sameAnswerNo;
        });
        if (typeof optionData === 'undefined') {
          return [];
        }
        return optionData;
      };
    },
    monthOption() {
      return TimeOptionHelper.getMonthOptions();
    },
    dayOption() {
      return TimeOptionHelper.getDayOptions();
    },
  },
  async created() {
    if (this.isChangePlan && !this.isEditChange()) {
      await this.setInintOldData();
    }
    for (let i = 0; i < this.maxAnswerNums; i += 1) {
      // 入れ物作成
      const template = {
        year: '',
        month: '',
        day: '',
      };
      this.dates.push(template);
      // 日付データ分解＆格納
      let date;
      if (!this.isLog) {
        date = this.optionData(this.options[2].id, i + 1).field_answer_text;
      } else {
        date = this.logData[`${this.fieldId}_${this.options[2].id}_${i + 1}`].field_answer_text;
      }
      if (date) {
        const dateArray = date.split('-');
        [
          this.dates[i].year,
          this.dates[i].month,
          this.dates[i].day,
        ] = dateArray;
      }
    }
  },
};
</script>

<style scoped>
  .collaborator-wrap {
    margin-left: -27px;
  }

  .errItem {
    border:solid 1px #dc3545 !important;
  }
  .error {
    font-size: 80%;
    color: #dc3545;
    /* bootstrapで太字が当てられていたので。 */
    font-weight: normal;
  }

  input, select {
    height: 50px;
  }

  .addBtn {
    color:#293CB0 !important;
    border-color: #293CB0 !important;
    border-radius: 30px;
    width: 200px;
  }

  .plus {
    margin-bottom: 4px;
  }

  .btn-outline-primary:hover {
    background-color: #fff;
    opacity: 0.6;
  }

  .inputTemplate_1 {
    width: 200px;
  }
  .inputTemplate_2 {
    width: 300px;
  }
  .inputTemplate_3 {
    width: 310px;
  }
  .inputTemplate_4 {
    width: 638px;
  }

  .mr-20px {
    margin-right: 20px;
  }

  .mt-10px {
    margin-top: 10px;
  }

  .deleteText {
    position: absolute;
    right: 50px;
  }

  .deleteBtn {
    position: absolute;
    right: 50px;
    transform: translateY(280%);
    cursor: pointer;
  }

  .groupInputs {
    width: 750px;
  }

  /* 最初の入力欄の集合以外の上部に線を入れる */
  .groupInputs:nth-of-type(n+2) {
    border-top: solid 1px #E6E6E6;
  }

  /* 中 */
  input.template_2 {
    width: 400px;
    max-width: 400px;
  }

  /* 短 */
  input.template_3 {
    width: 220px;
    max-width: 220px;
  }

  /* 極短 */
  input.template_4 {
    width: 120px;
    max-width: 120px;
  }

  .yearWrap {
    max-width: 170px;
    display: table;
  }

  .monthWrap, .dayWrap, .ageWrap{
    max-width: 120px;
    display: table;
  }
  .textWrap:not(.year){
    padding-left: 10px;
  }

  .coworker-textarea {
    width: 638px;
  }

  .number-badge {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 70px;
  }
  .drop {
    height: 120px;
    width: 638px;
    background-color: #F3F3F3;
    border-color: #CCC;
    border-style: dashed;
    border-radius: 10px;
    border-width: 1px;
  }

  .dragText {
    color: #BBB;
  }

  .fileText {
    color: #0A8EA7;
  }

  .fileNotation {
    color: #BBB;
  }

  .is-dragging.drop {
    background-color: #E6E6E6;
    border-color: #6E6E6E;
  }

  .is-dragging {
    color: #6E6E6E;
  }

  .selectFile {
    cursor: pointer;
  }
  .selectFile:hover {
    text-decoration: underline;
  }

  .delete_file_btn {
    width: 20px;
  }
  .delete_file_btn:hover {
    cursor: pointer;
  }
  .label-msg-view2, .bottom-msg-view2 {
    color: #dc3545;
    white-space: break-spaces;
  }
  .label-msg-view7, .bottom-msg-view7 {
    color: #dc3545;
    font-weight: bold;
  }
</style>
