<template>
  <div class="mt-10px">

    <div
      class="my-2">
      <div v-for="(line) of maxAnswerNums[fieldId] " :key="line"
        class="groupInputs mt-2 mb-3">
        <div class="mt-10px">
          <span class="deleteText mt-10px">削除</span>
        </div>
        <div class="deleteBtn">
          <img
            src="@/assets/svg/close.svg"
            v-if="writable"
            @click="removeTr(line)"
            class="tdDeleteBtn">
        </div>
        <div class="input-area">
          <div
            v-for="(option, index) in options" :key="index"
            class="inlineBlock mr-20px mt-10px">
            <span class="ml-1 mb-1">{{option.label}}</span>
            <b-input-group
                v-if="option.input_type===tableInputTypes.TEXT ||
                option.input_type===tableInputTypes.NUMBER">
            <b-form-input
              :type="controlInputType(option.rule_id_list)"
              :disabled="!writable"
              :class="[
                !isLog && optionData(option.id, line).errMessages.length > 0 ? 'errItem' : '',
                'inputTemplate_' + option.view_template,
              ]"
              :maxLength="option.max"
              :value="!isLog ? optionData(option.id, line).field_answer_text
              : logData[`${fieldId}_${option.id}_${line}`].field_answer_text"
              :formatter="controlFormatter(option.rule_id_list)"
              @input="kickSetText($event, option.id, line)"
              :placeholder="option.placeholder"/>
              <b-input-group-append
                class="ml-1 flex flexMiddle">
                {{option.suffix}}
              </b-input-group-append>
            </b-input-group>
            <b-form-select
              :disabled="!writable"
              :class="[
                !isLog && optionData(option.id, line).errMessages.length > 0 ? 'errItem' : '',
                'inputTemplate_' + option.view_template,
                'block',
              ]"
              :value="!isLog ? optionData(option.id, line).field_answer_id
              : logData[`${fieldId}_${option.id}_${line}`].field_answer_id"
              v-if="option.input_type===tableInputTypes.SELECT"
              @input="kickSetSelect($event, option.id, line)"
              :options="option.choice_texts">
              >
              <template v-slot:first>
                <option :value="null" selected>-- 選択してください。 --</option>
              </template>
            </b-form-select>
            <span
              v-if="option.input_type===tableInputTypes.FIXED">
              {{option.choice_texts}}
            </span>

            <div class="ml-1"
              v-if="!isLog && !isOld">
              <p
                v-for="(msg, index) in optionData(option.id, line).errMessages" :key="index"
                class="error my-1">
                {{msg}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex adjust-left"
      v-if="writable">
      <b-button
      class="addBtn"
      :disabled="!(this.maxAnswerNums[this.fieldId] < this.max_input_number)"
      @click="addTr"
      variant="outline-primary">
        <img class="plus" src="@/assets/svg/plus.svg">
        入力欄を追加
      </b-button>
    </div>
  </div>
</template>
<script>
import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'PartsApplicationTable',
  props: {
    fieldId: { type: Number },
    is_variable: { type: [Boolean, Number], default: true },
    input_number: { type: Number, default: 1 },
    min_input_number: { type: Number, default: 1 },
    max_input_number: { type: Number, default: 1 },
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            input_type: null,
            prefix: '',
            suffix: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
          },
        ];
      },
    },
    notice: {},
    writable: { type: Boolean, default: false },
    isOld: { type: Boolean, default: false },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
    controlFormatter: { typr: Function },
    controlInputType: { type: Function },
  },
  data() {
    return {
    };
  },
  methods: {
    addTr() {
      if (this.maxAnswerNums[this.fieldId] < this.max_input_number) {
        // データ上で入力項目の数を追加し、入力欄を追加する
        const newLineNum = this.maxAnswerNums[this.fieldId] + 1;
        const addParam = this._getInputParameter(newLineNum);
        const ansNumParam = {
          fieldId: this.fieldId,
          newLineNum,
        };
        this.$store.commit('gasApplicationInput/setMaxAnswerNums', ansNumParam);
        this.$store.commit('gasApplicationInput/addInputs', addParam);
      }
    },
    removeTr(line) {
      if (this.maxAnswerNums[this.fieldId] > this.min_input_number) {
        // 末尾の入力欄を削除し、入力項目の数も１つ減らす
        const removeParam = {
          fieldId: this.fieldId,
          line,
        };
        const newLineNum = this.maxAnswerNums[this.fieldId] - 1;
        const ansNumParam = {
          fieldId: this.fieldId,
          newLineNum,
        };
        this.$store.commit('gasApplicationInput/removeInputs', removeParam);
        this.$store.commit('gasApplicationInput/setMaxAnswerNums', ansNumParam);
      }
    },
    _getInputParameter(newLineNum) {
      const params = [];
      const commonParam = {
        field_id: this.fieldId,
        answer_no: newLineNum,
      };
      this.options.forEach((option) => {
        const param = Object.assign({}, commonParam);
        param.option_id = option.id;
        params.push(param);
      });
      return params;
    },
    kickSetText(value, optionId, lineNum) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: lineNum,
        value,
      };
      this.$emit('kickSetText', param);
    },
    kickSetSelect(value, optionId, lineNum) {
      let setValue = value;
      if (setValue) {
        setValue = Number(setValue);
      }
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: lineNum,
        value: setValue,
      };
      this.$emit('kickSetSelect', param);
    },
  },
  computed: {
    maxAnswerNums() {
      return this.$store.state.gasApplicationInput.maxAnswerNums;
    },
    tableInputTypes() {
      return CONST_OPTIONS.TABLE_INPUT_TYEPS;
    },
    inputs() {
      if (this.isLog) {
        return [];
      }
      if (!this.isOld) {
        return this.$store.state.gasApplicationInput.inputs;
      }
      return this.$store.state.gasApplicationOldInput.inputs;
    },
    optionData() {
      const self = this;
      return (optionId, line) => {
        const optionData = self.inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          const sameAnswerNo = input.answer_no === line;
          return sameField && sameOption && sameAnswerNo;
        });
        if (typeof optionData === 'undefined') {
          return [];
        }
        return optionData;
      };
    },
  },
};
</script>

<style scoped>
  .errItem {
    border:solid 1px #dc3545 !important;
  }
  .error {
    font-size: 80%;
    color: #dc3545;
    /* bootstrapで太字が当てられていたので。 */
    font-weight: normal;
  }

  input, select {
    height: 50px;
  }

  .addBtn {
    color:#293CB0 !important;
    border-color: #293CB0 !important;
    border-radius: 30px;
    width: 200px;
  }

  .plus {
    margin-bottom: 4px;
  }

  .btn-outline-primary:hover {
    background-color: #fff;
    opacity: 0.6;
  }

  .inputTemplate_1 {
    width: 700px;
  }
  .inputTemplate_2 {
    width: 340px;
  }

  .inputTemplate_3 {
    width: 220px;
  }

  .inputTemplate_4 {
    width: 135px;
  }

  .mr-20px {
    margin-right: 20px;
  }

  .mt-10px {
    margin-top: 10px;
  }

  .deleteText {
    position: absolute;
    right: 0px;
  }

  .deleteBtn {
    position: absolute;
    height: 100%;
    right: 0px;
    transform: translateY(50%);
    cursor: pointer;
  }

  .groupInputs {
    position: relative;
    width: 750px;
  }

  /* 最初の入力欄の集合以外の上部に線を入れる */
  .groupInputs:nth-of-type(n+2) {
    border-top: solid 1px #E6E6E6;
  }

  .input-area {
    margin-left: -5px;
    display: flex;
    flex-wrap: wrap;
  }

</style>
